import Seo from '../../components/molecules/Seo/Seo'
import {Grid} from '@mui/material'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import {getAllDataFromPath, updateOrCreateDocument} from '../../firebase'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import MagicLoader from '../../components/atoms/MagicLoader/MagicLoader'
import SeoHelper from '../../helpers/SeoHelper/SeoHelper'
import {useTheme} from '@mui/styles'

const EditTagPage = () => {
    const theme = useTheme()
    const idElement = window.location.pathname.split('/')[2]
    const {enqueueSnackbar} = useSnackbar()
    const [silos, setSilos] = useState(null)
    const [silosItems, setSilosItems] = useState(null)
    const style = {
        root: {
            width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 110px)',
            padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px'
        },
        arrayNotExist: {
            fontSize: '16px',
            marginTop: '20px',
            color: '#324b54',
            background: '#ededed',
            padding: '20px',
            width: 'auto',
            borderRadius: '50px',
        }
    }
    const [formDataSilos, setFormDataSilos] = useState({})
    const handleSubmit = (e) => {
        updateOrCreateDocument('tags', idElement, e).then((res) => {
            if (res) {
                enqueueSnackbar('Zapisano dane poprawnie.', {variant: 'success'})
            }
        })
    }
    const updateSilos = () => {
        getAllDataFromPath('silos').then((res) => {
            if (res) {
                setSilos(res)
            }
        })
    }
    useEffect(() => {
        if (silosItems) {
            getAllDataFromPath('tags').then((res) => {
                if (res) {
                    res?.forEach(el => {
                        if (el?.id === idElement) {
                            const dataSeoTemp = {}
                            dataSeoTemp['seo: category'] = el?.data['seo: category'] || ''
                            dataSeoTemp['seo: description'] = el?.data['seo: description'] || ''
                            dataSeoTemp['seo: title'] = el?.data['seo: title'] || ''
                            dataSeoTemp['seo: fbPageId'] = el?.data['seo: fbPageId'] || ''
                            dataSeoTemp['seo: keywords'] = el?.data['seo: keywords'] || ''
                            dataSeoTemp['seo: ogDescription'] = el?.data['seo: ogDescription'] || ''
                            dataSeoTemp['seo: ogImage'] = el?.data['seo: ogImage'] || ''
                            dataSeoTemp['seo: ogTitle'] = el?.data['seo: ogTitle'] || ''
                            dataSeoTemp['seo: page'] = el?.data['seo: page'] || ''
                            dataSeoTemp['seo: prase'] = el?.data['seo: prase'] || ''
                            dataSeoTemp['seo: twitterDescription'] = el?.data['seo: twitterDescription'] || ''
                            dataSeoTemp['seo: twitterId'] = el?.data['seo: twitterId'] || ''
                            dataSeoTemp['seo: twitterImage'] = el?.data['seo: twitterImage'] || ''
                            dataSeoTemp['seo: twitterTitle'] = el?.data['seo: twitterTitle'] || ''
                            const dataSeo = {
                                data: dataSeoTemp
                            }
                            const formDataTags = {
                                elements: [
                                    {
                                        name: 'silos',
                                        type: 'select',
                                        value: el?.data?.silos,
                                        label: 'Wybierz silos, z którego tag pochodzi',
                                        items: silosItems,
                                    },
                                    {
                                        name: 'name',
                                        value: el?.data?.name,
                                        label: 'Nazwa tagu',
                                        type: 'text',
                                    },
                                ],
                            }
                            SeoHelper.addFieldSeo(formDataTags, [dataSeo])
                            formDataTags.elements.push({ type: 'button', value: 'Zapisz tag' })
                            setFormDataSilos(formDataTags)
                        }
                    })
                }
            })
        }
    }, [silosItems])
    useEffect(() => {
        if (silos?.length > 0) {
            const temp = []
            silos.forEach(el => {
                const data = el?.data
                temp.push({ value: data?.name, name: data?.name })
            })
            setSilosItems(temp)
        }
    }, [silos])
    useEffect(() => {
        updateSilos()
    }, [])
    return (
        <>
            <Seo data={{'seo: title': `${theme?.config?.seo?.company} - Panel administracyjny`}}/>
            <MagicLoader variable={silos && silosItems && formDataSilos?.elements?.length > 0}>
                <div style={style.root}>
                    <Grid container spacing={8}>
                        <Grid item md={12} xs={12}>
                            <FormGenerator data={formDataSilos} submit={(e) => handleSubmit(e)}/>
                        </Grid>
                    </Grid>
                </div>
            </MagicLoader>
        </>
    )
}

export default EditTagPage
