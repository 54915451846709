import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import {useTheme} from '@mui/styles'
import {Grid} from '@mui/material'
import FormGenerator from '../../organisms/FormGenerator/FormGenerator'
import Dot from '../../atoms/Dot/Dot'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import ApiHelper from '../../../helpers/ApiHelper/ApiHelper'
import {useSnackbar} from 'notistack'

const ContactForm = () => {
  const theme = useTheme()
  const {enqueueSnackbar} = useSnackbar()
  const { phone, email, address, hours, nip, regon } = theme.config.seo
  const style = {
    root: {
      background: theme.palette.secondary.second,
      padding: BreakpointsHelper.isMobile() ? '30px' : '50px',
      display: 'flex',
      minHeight: '500px',
      borderRadius: '20px',
      marginBottom: '50px',
      flexDirection: 'column',
      marginTop: '50px',
    },
    h2: {
      fontSize: BreakpointsHelper.isMobile() ? '38px' : '52px',
      fontWeight: '600',
      textAlign: 'left',
      width: '100%',
      color: 'white'
    },
    h3: {
      fontSize: '18px',
      fontWeight: '400',
      marginTop: '10px',
      textAlign: 'left',
      width: '100%',
      color: 'white'
    },
    button: {
      marginTop: '20px',
      padding: '6px 16px',
      borderRadius: '6px',
      minHeight: '31px',
      cursor: 'pointer',
      textTransform: 'uppercase',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonActive: {
      background: '#324b54',
      color: 'white',
      marginTop: '20px',
      padding: '6px 16px',
      borderRadius: '6px',
      minHeight: '31px',
      cursor: 'pointer',
      textTransform: 'uppercase',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    articleImageActive: {
      width: '100%',
      marginTop: '20px',
      borderRadius: '20px'
    }
  }
  const formData = {
    elements: [
      {
        name: 'name',
        label: 'Imię i nazwisko / Firma',
        type: 'text',
      },
      {
        name: 'phone',
        label: 'Telefon',
        type: 'text',
      },
      {
        name: 'email',
        label: 'Email',
        type: 'text',
      },
      {
        name: 'services',
        label: 'Usługi',
        type: 'select',
        items: [
          { value: 'Księgowość', name: 'Księgowość' },
          { value: 'Kadry i płace', name: 'Kadry i płace' },
          { value: 'Kontroling', name: 'Kontroling' },
          { value: 'Usługi zarządzania', name: 'Usługi zarządzania' },
          { value: 'Due diligence', name: 'Due diligence' },
          { value: 'Doradztwo biznesowo – ekonomiczne', name: 'Doradztwo biznesowo – ekonomiczne' },
        ],
      },
      {
        name: 'message',
        label: 'Wiadomość',
        type: 'textarea',
      },
      {
        type: 'button',
        value: 'Wyślij'
      }
    ]
  }
  const handleSubmit = (e) => {
    const data = {
      mail_to: 'kontakt@your-site.pl',
      name: e?.name,
      phone: e?.phone,
      email: e?.email,
      subject: e?.services,
      message: e?.message,
      company: theme.config.seo.company,
    }
    ApiHelper.post('https://api.your-site.pl/email', data).then(() => {
        enqueueSnackbar('Dane wysłano poprawnie.', {variant: 'success'})
    })
  }
  return (
    <Box sx={style.root}>
      <Box sx={{ marginTop: '20px' }}>
        <Grid container spacing={4}>
          <Grid item md={6} xs={12}>
            <Typography variant="h2" sx={style.h2}>
              Skorzystaj z osobistej<br/> konsultacji<Dot/>
            </Typography>
            <Typography variant="h3" sx={style.h3}>
              Odpowiemy na wszelkie pytania
            </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '50px' }}>
                <Typography sx={{ fontSize: '24px', fontWeight: '900', marginBottom: '10px', color: 'white' }}>Biuro</Typography>
                <Typography sx={{ color: 'white' }}>{address}</Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '30px' }}>
                <Typography sx={{ fontSize: '24px', fontWeight: '900', marginBottom: '10px', color: 'white' }}>Email</Typography>
                <Typography sx={{ color: 'white' }}>{email}</Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '30px' }}>
                <Typography sx={{ fontSize: '24px', fontWeight: '900', marginBottom: '10px', color: 'white' }}>Telefon</Typography>
                <Typography sx={{ color: 'white' }}>{phone}</Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '30px' }}>
                <Typography sx={{ fontSize: '24px', fontWeight: '900', marginBottom: '10px', color: 'white' }}>Godziny otwarcia</Typography>
                <Typography sx={{ color: 'white' }}>Pon - Pt: {hours.ponPt}</Typography>
                <Typography sx={{ color: 'white' }}>Sobota: {hours.sob}</Typography>
                <Typography sx={{ color: 'white' }}>Niedziela: {hours.nd}</Typography>
              </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            <Box sx={{ background: 'white', borderRadius: '30px', padding: '30px' }}>
              <FormGenerator data={formData} submit={(e) => handleSubmit(e)} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default ContactForm
