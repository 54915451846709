import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import './DashboardPage.scss'
import Typography from '@mui/material/Typography'
import {useEffect, useState} from 'react'
import {Grid, useTheme} from '@mui/material'
import {useSnackbar} from 'notistack'
import Competition from '../../components/atoms/Competition/Competition'
import {getAllDataFromPath} from '../../firebase'
import DateHelper from '../../helpers/DateHelper/DateHelper'
import Seo from '../../components/molecules/Seo/Seo'

const DashboardPage = (props) => {
  const theme = useTheme()
  const [tournaments, setTournaments] = useState(null)
  const style = {
    root: {
      width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 110px)',
      padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px'
    },
    arrayNotExist: {
      fontSize: '16px',
      marginTop: '20px',
      color: '#324b54',
      background: '#ededed',
      padding: '20px',
      width: 'auto',
      marginLeft: '20px',
      borderRadius: '50px',
    }
  }
  useEffect(() => {
    getAllDataFromPath('tournaments').then((res) => {
      if (res) {
        res?.sort((el, next) => {
          const dateEl = el?.data?.date?.split('-')
          const dateNext = next?.data?.date?.split('-')
          const elDate = new Date(dateEl[0], dateEl[1], dateEl[2])
          const nextDate = new Date(dateNext[0], dateNext[1], dateNext[2])
          return elDate - nextDate
        })
        setTournaments(res)
      }
    })
  }, [])
  return (
    <>
      <Seo data={{ 'seo: title': `${theme?.config?.seo?.company} - Panel administracyjny` }}/>
      <div style={style.root}>
        <Grid container spacing={4}>
          <Grid item md={12} xs={12}>
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '30px',
                marginBottom: '10px'
              }}
            >
              Lista przekazanych danych
            </Typography>
          </Grid>
          {
            tournaments?.length > 0 ? tournaments?.map((el, index) => {
              const data = el?.data
                return (
                  <Competition
                    key={index}
                    data={data}
                    last
                  />
                )
            }) : (
            <Typography sx={style.arrayNotExist}>Nie ma dostępnych danych.</Typography>
            )
          }
        </Grid>
      </div>
    </>
  )
}

export default DashboardPage
