import Typography from '@mui/material/Typography'
import Page from '../../components/molecules/Page/Page'
import {Grid, Link} from '@mui/material'
import Seo from '../../components/molecules/Seo/Seo'
import BookImage from '../../assets/images/Zdjęcia (8).png'
import BookImage2 from '../../assets/images/old/30.png'
import ContactBanner from '../../components/molecules/ContactBanner/ContactBanner'
import Footer from '../../components/molecules/Footer/Footer'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import {ReactComponent as Box1} from '../../assets/images/sectionIcons/Załatwisz.svg'
import {ReactComponent as Box2} from '../../assets/images/sectionIcons/Przechowasz.svg'
import {ReactComponent as Box3} from '../../assets/images/sectionIcons/Wystawisz (1).svg'
import {ReactComponent as Box4} from '../../assets/images/sectionIcons/Prześlesz (1).svg'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'

const BookkeepingPage = () => {
  const style = {
    titleBox: {
      fontSize: '22px',
      textAlign: 'left',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginBottom: '10px',
    },
    descBox: {
      fontSize: '22px',
      textAlign: 'left',
    },
    img: {
      fill: '#2cbc63',
      width: '40px',
      marginRight: '15px',
    },
    image: {
      width: '100%',
      display: 'flex',
      maxWidth: '320px',
      marginBottom: '30px'
    },
    box: {
      display: 'flex',
      flexDirection: 'column',
      background: 'white',
      padding: '40px',
      borderRadius: '30px',
      minHeight: '350px',
      transition: '0.3s all',
      justifyContent: 'flex-start',
      cursor: 'pointer',
      '&:hover': {
        transform: 'scale(1.2)',
        transition: '0.3s all',
      }
    },
    h3: {
      fontSize: '22px',
      fontWeight: '400',
      marginTop: '10px',
      textAlign: 'center',
      width: '100%'
    },
    desc: {
      fontSize: '22px'
    },
    h3Topic: {
      fontSize: '22px',
      fontWeight: '400',
      marginTop: '10px',
      textAlign: BreakpointsHelper.isMobile() ? 'left' : 'left',
      width: '100%'
    },
    h3Center: {
      fontSize: '22px',
      fontWeight: '400',
      marginTop: '10px',
      textAlign: 'center',
      width: '100%'
    },
    h4: {
      fontSize: '28px',
      fontWeight: '600',
      textAlign: 'center',
      display: 'flex',
      margin: '0 auto',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '10px',
      width: '100%',
      marginBottom: '20px',
      minHeight: '80px'
    },
    cat: {
      width: '60px',
      fill: '#2cbc63',
      marginBottom: '20px',
    },
  }
  return (
    <>
      <Seo data={{'seo: title': 'Complex Finance - Księgowość'}}/>
      <Page
        image={BookImage}
        title={'Księgowość'}
        small={'Obsługa wszelkich form prowadzonej działalności'}
        description={(
          <>
            <Grid container spacing={4}>
              <Grid item md={12} xs={12}>
                <Typography sx={style.descBox}>
                  W <b>Complex Finance</b> rozumiemy, że każda firma jest wyjątkowa i wymaga indywidualnego podejścia do
                  księgowości oraz zarządzania finansami. Dlatego nasze usługi są projektowane z myślą o maksymalnym
                  dostosowana do specyfiki prowadzonej przez Ciebie działalności.
                </Typography>
                <Typography sx={style.descBox}>
                  Niezależnie od formy opodatkowania czy branży, w której działasz, zapewniamy kompleksową obsługę,
                  pozwala nie tylko na efektywne zarządzanie finansami, ale również na optymalizację podatkową i
                  redukcję kosztów operacyjnych.
                </Typography>
                <Button
                  onClick={() => window.open('/contact', '_self')}
                  variant="contained"
                  sx={{ marginRight: '20px', color: 'white', borderRadius: '20px !important', height: '46px', marginTop: '30px', width: BreakpointsHelper.isMobile() ? '100%' : 'auto' }}
                >
                  Skontaktuj się z nami
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      />
      <Page
        centerHeading
        section2Center
        title={'Usługi'}
        small={'Oferujemy najlepsze możliwe rozwiązania dla Twojej firmy'}
        description={(
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', maxWidth: '1024px', margin: '50px auto 0 auto' }}>
              <Grid container spacing={8}>
                <Grid item md={6} xs={12}>
                  <Box sx={style.box}>
                    <Typography variant="h2" sx={style.h4}>
                      <Box1 style={style.cat}/>
                      Zapewniamy
                    </Typography>
                    <Typography variant="h3" sx={style.h3}>
                      Obsługę rachunkową dla firm i spółek rozliczających się według zasad ogólnych, podatku liniowego,
                      ryczałtu
                      ewidencjonowanego i karty podatkowej.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box sx={style.box}>
                    <Typography variant="h2" sx={style.h4}>
                      <Box2 style={style.cat}/>
                      Specjalizujemy się
                    </Typography>
                    <Typography variant="h3" sx={style.h3}>
                      W kompleksowej obsłudze spółek z ograniczoną odpowiedzialnością (Sp. z o.o.), będących częścią
                      polskich, jak zagranicznych grup kapitałowych.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box sx={style.box}>
                    <Typography variant="h2" sx={style.h4}>
                      <Box3 style={style.cat}/>
                      Przygotowujemy
                    </Typography>
                    <Typography variant="h3" sx={style.h3}>
                      Politykę rachunkowości oraz budujemy plan kont – wszystko według indywidualnych preferencji i potrzeb.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box sx={style.box}>
                    <Typography variant="h2" sx={style.h4}>
                      <Box4 style={style.cat}/>
                      Oferujemy
                    </Typography>
                    <Typography variant="h3" sx={style.h3}>
                      Możliwość prowadzenia księgowości w języku angielskim oraz na programach udostępnionych przez Klienta.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      />
      <Page
        revert
        section2Center
        image={BookImage2}
        small={'Księgowość online'}
        description={(
          <>
            <Grid container spacing={4}>
              <Grid item md={12} xs={12}>
                <Typography sx={style.descBox}>
                  Skany dokumentów, dane w chmurze, dokumentacja i wyniki firmy oraz spotkania online, przez
                  dedykowany panel Klienta – eco, nowocześnie i szybko. Wszystko upraszczamy i automatyzujemy, abyś na
                  bieżąco mógł kontrolować swój biznes oraz podejmować trafne decyzje.
                  <br/>
                  <uL style={{ marginTop: '20px' }}>
                    <li>
                      <b>Przesyłaj faktury i inne dokumenty w najnowocześniejszy sposób</b>
                      <br/>
                      Nie musisz nigdy więcej tracić czasu na dojazd do biura księgowego. Wszystkie dokumenty prześlesz za
                      pomocą wygodnej aplikacji dostępnej na smartfony, tablety i komputery. Ty wybierasz, w jakiej formie i
                      jak często przekazujesz najważniejsze informacje – robiąc zdjęcia poprzez aplikację na smartfonie lub
                      tworząc paczkę dokumentów w wybranym formacie.
                    </li>
                    <li>
                      <b>Oszczędność miejsca</b>
                      <br/>
                      Na względzie mamy dobro środowiska, dlatego ograniczamy do minimum obieg dokumentów papierowych.
                      Dane
                      przechowujemy w formie elektronicznej na bezpiecznych serwerach chmurowych. Wszystko czego
                      potrzebujesz, masz zawsze w zasięgu ręki – wystarczy kilka kliknięć.
                    </li>
                  </uL>
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      />
      <Page
        centerHeading
        section2Center
        title={'Usługi'}
        small={'Co dla nas ważne'}
        description={(
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', maxWidth: '1024px', margin: '50px auto 0 auto' }}>
              <Grid container spacing={8}>
                <Grid item md={4} xs={12}>
                  <Box sx={style.box}>
                    <Typography variant="h2" sx={style.h4}>
                      <Box1 style={style.cat}/>
                      Załatwisz
                    </Typography>
                    <Typography variant="h3" sx={style.h3}>
                      z księgowym wszystkie sprawy przez internet lub telefon.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Box sx={style.box}>
                    <Typography variant="h2" sx={style.h4}>
                      <Box2 style={style.cat}/>
                      Przechowasz
                    </Typography>
                    <Typography variant="h3" sx={style.h3}>
                      wszystkie dokumenty księgowe na wirtualnym dysku.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Box sx={style.box}>
                    <Typography variant="h2" sx={style.h4}>
                      <Box3 style={style.cat}/>
                      Wystawisz
                    </Typography>
                    <Typography variant="h3" sx={style.h3}>
                      faktury oraz wyślesz je bezpośrednio z aplikacji.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Box sx={style.box}>
                    <Typography variant="h2" sx={style.h4}>
                      <Box4 style={style.cat}/>
                      Prześlesz
                    </Typography>
                    <Typography variant="h3" sx={style.h3}>
                      dokumenty do księgowego przez stronę lub aplikację.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Box sx={style.box}>
                    <Typography variant="h2" sx={style.h4}>
                      <Box4 style={style.cat}/>
                      Nadasz
                    </Typography>
                    <Typography variant="h3" sx={style.h3}>
                      dostęp do konta każdemu współpracownikowi
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Box sx={style.box}>
                    <Typography variant="h2" sx={style.h4}>
                      <Box4 style={style.cat}/>
                      Przeanalizujesz
                    </Typography>
                    <Typography variant="h3" sx={style.h3}>
                      wydatki i przychody, aby zoptymalizować koszty.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      />
      <ContactBanner/>
      <Footer/>
    </>
  )
}

export default BookkeepingPage
