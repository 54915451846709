import Page from '../../components/molecules/Page/Page'
import Footer from '../../components/molecules/Footer/Footer'
import Typography from '@mui/material/Typography'
import {Grid} from '@mui/material'
import Seo from '../../components/molecules/Seo/Seo'
import ConsultingImage from '../../assets/images/old/0.png'
import ContactBanner from '../../components/molecules/ContactBanner/ContactBanner'
import Button from '@mui/material/Button'
import {ReactComponent as Box1} from '../../assets/images/sectionIcons/Doradztwo finansowe.svg'
import {ReactComponent as Box2} from '../../assets/images/sectionIcons/Strategia.svg'
import {ReactComponent as Box3} from '../../assets/images/sectionIcons/Optymalizacja procesów.svg'
import {ReactComponent as Box4} from '../../assets/images/sectionIcons/Restrukturyzacja.svg'
import Box from '@mui/material/Box'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'

const BusinessAndEconomicConsultingPage = () => {
  const style = {
    desc: {
      '> *': {
        fontSize: '22px',
        fontWeight: '600',
      },
      fontSize: '22px',
    },
    titleBox: {
      fontSize: '22px',
      textAlign: 'left',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginBottom: '10px',
    },
    img: {
      fill: '#2cbc63',
      width: '40px',
      marginRight: '15px',
    },
    image: {
      width: '100%',
      display: 'flex',
      maxWidth: '320px',
      marginBottom: '30px'
    },
    box: {
      display: 'flex',
      flexDirection: 'column',
      background: 'white',
      padding: '40px',
      borderRadius: '30px',
      minHeight: '350px',
      transition: '0.3s all',
      justifyContent: 'flex-start',
      cursor: 'pointer',
      '&:hover': {
        transform: 'scale(1.2)',
        transition: '0.3s all',
      }
    },
    h3: {
      fontSize: '22px',
      fontWeight: '400',
      marginTop: '10px',
      textAlign: 'center',
      width: '100%'
    },
    h3Topic: {
      fontSize: '22px',
      fontWeight: '400',
      marginTop: '10px',
      textAlign: BreakpointsHelper.isMobile() ? 'left' : 'left',
      width: '100%'
    },
    h3Center: {
      fontSize: '22px',
      fontWeight: '400',
      marginTop: '10px',
      textAlign: 'center',
      width: '100%'
    },
    h4: {
      fontSize: '28px',
      fontWeight: '600',
      textAlign: 'center',
      display: 'flex',
      margin: '0 auto',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '10px',
      width: '100%',
      marginBottom: '20px',
      minHeight: '80px'
    },
    cat: {
      width: '60px',
      fill: '#2cbc63',
      marginBottom: '20px',
    },
  }
  return (
    <>
      <Seo data={{ 'seo: title': 'Complex Finance - Doradztwo biznesowo-ekonomiczne' }}/>
      <Page
        image={ConsultingImage}
        title={'Doradztwo biznesowo-ekonomiczne'}
        small={'Planujesz zmiany? Pomożemy'}
        description={(
          <>
            <Typography sx={style.desc}>
              Stoisz przed wyzwaniem rozwoju swojego biznesu lub poszukujesz <b>wsparcia
              finansowego</b>? W Complex Finance dokładnie przeanalizujemy Twoją sytuację
              biznesową, aby zaproponować najbardziej optymalny plan rozwoju Twojej
              firmy oraz przedstawić dostępne opcje <b>finansowania zewnętrznego</b>. Nasze
              doświadczenie i wiedza są gwarancją, że znajdziemy rozwiązania idealnie
              dopasowane do potrzeb Twojego przedsiębiorstwa.
            </Typography>
            <Button
                onClick={() => window.open('/contact', '_self')}
                variant="contained"
                sx={{ marginRight: '20px', color: 'white', borderRadius: '20px !important', height: '46px', marginTop: '30px', width: BreakpointsHelper.isMobile() ? '100%' : 'auto' }}
            >
              Zadzwoń do nas
            </Button>
          </>
        )}
      />
      <Page
        centerHeading
        section2Center
        title={'Doradztwo biznesowo-ekonomiczne'}
        small={'Planujesz zmiany? Pomożemy'}
        description={(
          <Box sx={{ maxWidth: '1024px', margin: '50px auto 0 auto' }}>
            <Grid container spacing={8}>
              <Grid item md={6} xs={12}>
                <Box sx={style.box}>
                  <Typography variant="h2" sx={style.h4}>
                    <Box1 style={style.cat}/>
                    Doradztwo finansowe
                  </Typography>
                  <Typography variant="h3" sx={style.h3}>
                    Kredyt, leasing, dotacja, a może faktoring lub inny produkt? Doradzimy,
                    wynegocjujemy najlepsze warunki i dopełnimy wszelkich formalności.
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box sx={style.box}>
                  <Typography variant="h2" sx={style.h4}>
                    <Box2 style={style.cat}/>
                    Strategia
                  </Typography>
                  <Typography variant="h3" sx={style.h3}>
                    Chciałbyś rozwijać swój biznes? Masz pomysł, ale nie wiesz, jak się do
                    tego zabrać? Chętnie poznamy Twoją perspektywę i zaproponujemy plan
                    działania.
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box sx={style.box}>
                  <Typography variant="h2" sx={style.h4}>
                    <Box3 style={style.cat}/>
                    Optymalizacje procesów
                  </Typography>
                  <Typography variant="h3" sx={style.h3}>
                    Chcesz coś poprawić, usprawnić? Starasz się oszczędzać czas swój lub
                    pracowników? Zajmiemy się przeglądem struktury organizacyjnej oraz jej
                    optymalizacją.
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box sx={style.box}>
                  <Typography variant="h2" sx={style.h4}>
                    <Box4 style={style.cat}/>
                    Restrukturyzacja
                  </Typography>
                  <Typography variant="h3" sx={style.h3}>
                    Twój biznes ma się gorzej? Przeanalizujemy Twoje księgi i sytuację
                    finansową. Zaproponujemy system naprawczy i wskażemy koszty, które można
                    zredukować.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      />
      <ContactBanner />
      <Footer/>
    </>
  )
}

export default BusinessAndEconomicConsultingPage
