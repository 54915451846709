import React, { useRef } from 'react'
import { SnackbarProvider } from 'notistack'
import Button from '../../atoms/Forms/Button/Button'
import { Close } from '@mui/icons-material'

const ToastProvider = ({ children, theme }) => {
  const snackRef = useRef()
  const style = {
    buttonX: {
      color: 'black'
    }
  }
  return (
    <SnackbarProvider
      ref={snackRef}
      autoHideDuration={3000}
      maxSnack={theme?.config?.maxToasts || 3}
      action={(key) => (
        <Button
          variant="text"
          onClick={() => snackRef.current.closeSnackbar(key)}
          sx={style.buttonX}
          icon={<Close/>}
        />
      )}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      hideIconVariant={false}
      dense={false}
    >
      {children}
    </SnackbarProvider>
  )
}

export default ToastProvider
