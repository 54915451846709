import Page from '../../components/molecules/Page/Page'
import Footer from '../../components/molecules/Footer/Footer'
import Typography from '@mui/material/Typography'
import Seo from '../../components/molecules/Seo/Seo'
import InterimImage2 from '../../assets/images/old/18.png'
import InterimImage from '../../assets/images/old/40.png'
import ContactBanner from '../../components/molecules/ContactBanner/ContactBanner'
import Button from '@mui/material/Button'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'

const ManagementServicesPage = () => {
  const style = {
    desc: {
      '> *': {
        fontSize: '22px',
        fontWeight: '600',
      },
      fontSize: '22px',
    },
    titleBox: {
      fontSize: '22px',
      textAlign: 'left',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginBottom: '10px',
    },
    img: {
      fill: '#2cbc63',
      width: '40px',
      marginRight: '15px',
    }
  }
  return (
    <>
      <Seo data={{ 'seo: title': 'Complex Finance - Usługi zarządzania' }}/>
      <Page
        image={InterimImage}
        title={'Usługi zarządzania'}
        small={'CFO, Manager Finansowy, Główny Księgowy'}
        description={(
          <>
            <Typography sx={style.desc}>
              W obliczu rosnących <b>wyzwań kadrowych</b> i <b>luk kompetencyjnych</b> na kluczowych
              stanowiskach, firmy poszukują elastycznych rozwiązań, aby utrzymać
              ciągłość i efektywność operacyjną. Complex Finance odpowiada na te
              potrzeby, oferując zaawansowane usługi interim managementu.
            </Typography>
            <Typography sx={style.desc}>
              Specjalizujemy się w <b>strategicznym zarządzaniu</b> i nadzorze działów
              finansowo-księgowych, włączając w to Centra Usług Wspólnych (SSC),
              zapewniając płynne <b>wdrożenie systemów ERP</b>, oraz oferując wsparcie w
              kluczowych procesach biznesowych takich jak fuzje, przejęcia czy
              restrukturyzacje.
            </Typography>
              <Button
                  onClick={() => window.open('/contact', '_self')}
                  variant="contained"
                  sx={{ marginRight: '20px', color: 'white', borderRadius: '20px !important', height: '46px', marginTop: '30px', width: BreakpointsHelper.isMobile() ? '100%' : 'auto' }}
              >
                  Napisz do nas
              </Button>
          </>
        )}
      />
      <Page
        revert
        image={InterimImage2}
        title={'Usługi zarządzania'}
        small={'Przypadek sukcesu'}
        description={(
          <>
            <Typography sx={style.desc}>
              Nasze doświadczenie mówi samo za siebie. Współpracując z renomowanymi
              kancelariami prawnymi zarówno z Polski, jak i zagranicy, stawiliśmy
              czoła wyjątkowo trudnemu zadaniu. Przeprowadziliśmy <b>skuteczną
              restrukturyzację spółki</b> po ogłoszeniu upadłości jej jedynego,
              zagranicznego udziałowca - był to <b>pierwszy taki przypadek w skali kraju</b>.
            </Typography>
            <Typography sx={style.desc}>
              Dzięki strategicznemu podejściu i skutecznej koordynacji działań, udało
              nam się nie tylko przejść przez ten skomplikowany proces, ale również
              osiągnąć wyniki, które przekroczyły oczekiwania. Kluczowym sukcesem była
              ochrona <b>ciągłości produkcji</b>, co w sytuacji kryzysowej było wyzwaniem
              wymagającym zarówno elastyczności, jak i innowacyjnych rozwiązań
              zarządczych.
            </Typography>
            <Typography sx={style.desc}>
              Nasze zaangażowanie oraz <b>solidne relacje</b> z polskimi i międzynarodowymi
              partnerami biznesowymi odegrały kluczową rolę w utrzymaniu stabilności
              operacyjnej spółki. Dzięki temu, pomimo skomplikowanej sytuacji
              finansowej i prawnej, udało się zachować wszystkie miejsca pracy – co
              stanowiło priorytet zarówno dla nas, jak i dla zarządu firmy.
            </Typography>
          </>
        )}
      />
      <ContactBanner />
      <Footer/>
    </>
  )
}

export default ManagementServicesPage
