import ErrorsEnum from '../../enums/ErrorsEnum/ErrorsEnum'

export default {
  checkErrorCode: (status) => {
    switch (status) {
      case 200:
        return ErrorsEnum.OK.name
      case 301:
        return ErrorsEnum.MOVED_PERMANENTLY.name
      case 302:
        return ErrorsEnum.FOUND.name
      case 400:
        return ErrorsEnum.BAD_REQUEST.name
      case 401:
        return ErrorsEnum.UNAUTHORIZED.name
      case 402:
        return ErrorsEnum.PAYMENT_REQUIRED.name
      case 403:
        return ErrorsEnum.FORBIDDEN.name
      case 404:
        return ErrorsEnum.NOT_FOUND.name
      case 405:
        return ErrorsEnum.METHOD_NOT_ALLOWED.name
      case 406:
        return ErrorsEnum.NOT_ACCEPTABLE.name
      case 407:
        return ErrorsEnum.PROXY_AUTHENTICATION_REQUIRED.name
      case 408:
        return ErrorsEnum.REQUEST_TIME_OUT.name
      case 409:
        return ErrorsEnum.CONFLICT.name
      case 410:
        return ErrorsEnum.GONE.name
      case 411:
        return ErrorsEnum.LENGTH_REQUIRED.name
      case 412:
        return ErrorsEnum.PRECONDITION_FAILED.name
      case 413:
        return ErrorsEnum.REQUEST_ENTITY_TOO_LARGE.name
      case 414:
        return ErrorsEnum.REQUEST_URL_TOO_LARGE.name
      case 415:
        return ErrorsEnum.UNSUPPORTED_MEDIA_TYPE.name
      case 416:
        return ErrorsEnum.REQUESTED_RANGE_NOT_SATISFIABLE.name
      case 417:
        return ErrorsEnum.EXPECTATION_FAILED.name
      case 500:
        return ErrorsEnum.NOT_IMPLEMENTED.name
      case 501:
        return ErrorsEnum.BAD_GATEWAY.name
      case 502:
        return ErrorsEnum.SERVICE_UNAVAILABLE.name
      case 503:
        return ErrorsEnum.GATEWAY_TIME_OUT.name
      case 504:
        return ErrorsEnum.HTTP_VERSION_NOT_SUPPORTED.name
      case 505:
        return ErrorsEnum.INTERNAL_SERVER_ERROR.name
    }
  }
}
