import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {Grid} from '@mui/material'
import Button from '@mui/material/Button'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'

const Competition = (props) => {
  const { data, last } = props
  return (
    <Grid item md={3} xs={12}>
      <Box
        sx={{
          boxShadow: '2px 2px 10px 2px rgba(0,0,0,.1)',
          padding: '20px',
          marginBottom: '20px',
          background: 'white',
        }}
      >
        <Grid container spacing={4}>
          <Grid item md={last ? 7 : 12} xs={12}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              Dostępna chmura od księgowej: <a href={data?.link} style={{ paddingTop: '20px' }} target="_blank" rel="noreferrer">{data?.link}</a>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}

export default Competition
