import {useEffect, useState} from 'react'
import kontakt from '../../assets/images/kontakt.jpg'
import {getAllDataFromPath} from '../../firebase'
import Seo from '../../components/molecules/Seo/Seo'
import SizeWindow from '../../components/atoms/SizeWindow/SizeWindow'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {Button, Grid} from '@mui/material'
import Footer from '../../components/molecules/Footer/Footer'
import SkeletonLoader from '../../components/atoms/SkeletionLoader/SkeletonLoader'
import Dialog from '../../components/atoms/Dialog/Dialog'

const GalleryPage = () => {
  const [seoData, setSeoData] = useState(null)
  const [galleries, setGalleries] = useState(null)
  const [galleriesActive, setGalleriesActive] = useState(null)
  const [active, setActive] = useState(null)
  const [activeImage, setActiveImage] = useState(null)
  const [categories, setCategories] = useState(null)
  const style = {
    banner: {
      height: '300px',
      padding: '100px',
      display: 'flex',
      alignItems: 'center',
      width: 'calc(100% - 200px)',
      justifyContent: 'center',
      background: `url(${kontakt})`,
      borderRadius: '50px',
      backgroundPosition: 'center center',
      backgroundSize: '100% auto',
      fitObject: 'cover',
      position: 'relative',
      marginBottom: '100px',
      marginTop: '100px',
    },
    blackBg: {
      position: 'absolute',
      zIndex: 1,
      width: '100%',
      height: '100%',
      borderRadius: '50px',
      background: 'rgba(0,0,0,.3)'
    },
    texts: {
      position: 'absolute',
      zIndex: 2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    title: {
      color: 'white',
      fontWeight: 900,
      fontSize: '65px',
      lineHeight: 1.1
    },
    desc: {
      marginTop: '20px',
      color: 'white',
      fontWeight: 400,
      fontSize: '24px',
      lineHeight: 1.1
    }
  }
  const getSeo = () => {
    getAllDataFromPath('seo').then((res) => {
      if (res) {
        setSeoData(res)
      }
    })
  }
  const getGalleries = () => {
    getAllDataFromPath('galleries').then((res) => {
      if (res) {
        setGalleries(res)
      }
    })
  }
  const handleChange = (el) => {
    setActive(null)
    setActive(el)
  }
  const handleImage = (el) => {
    setActiveImage(null)
    setActiveImage(el)
  }
  useEffect(() => {
    getSeo()
    getGalleries()
  }, [])
  useEffect(() => {
    if (galleries?.length > 0) {
      const tempCat = []
      galleries?.forEach(el => {
        tempCat.push(el?.data?.categoryName)
      })
      setActive(tempCat[0])
      setCategories(tempCat)
    }
  }, [galleries])
  useEffect(() => {
    if (active && galleries) {
      galleries?.forEach(el => {
        if (el?.data?.categoryName === active) {
          setGalleriesActive([el])
        }
      })
    }
  }, [active, galleries])
  return (
    <>
      <Seo data={seoData?.length > 0 ? seoData.filter(el => el.id === 'GalleryPage')[0]?.data : {}}/>
      <SizeWindow minHeight="300px">
        <Box sx={style.banner}>
          <Box sx={style.texts}>
            <Typography sx={style.title}>
              Galeria
            </Typography>
          </Box>
          <Box sx={style.blackBg}/>
        </Box>
        <Box sx={{ marginBottom: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
          {
            categories?.length > 0 && categories?.map((el, index) => (
              <Button key={index} sx={{ marginRight: '20px', background: active === el ? '#324b54' : 'white', color: active === el ? 'white' : '#324b54', padding: '20px', cursor: 'pointer' }} onClick={() => handleChange(el)}>
                {el}
              </Button>
            ))
          }
        </Box>
        <SkeletonLoader height={'200px'} time={1500} variable={galleriesActive?.length > 0 && active} circle>
          <Grid container spacing={4}>
              {
                galleriesActive?.length > 0 ? galleriesActive?.map((el) => {
                  const dataImages = el?.data?.images
                  return dataImages?.map((img, indexImg) => {
                    return (
                      <Grid item md={3} key={indexImg}>
                        <img onClick={() => handleImage(img)} src={img} style={{ width: '100%', height: '250px', objectFit: 'cover', cursor: 'pointer' }}/>
                      </Grid>
                    )
                  })
                }) : (
                  <Typography>Brak dostępnych zdjęć w galerii</Typography>
                )
              }
          </Grid>
        </SkeletonLoader>
        {
          activeImage?.length > 0 && (
            <Dialog open={true} title="Podgląd zdjęcia" content={<img src={activeImage} alt={'poglad'} style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', maxHeight: 'calc(100vh - 100px)', objectFit: 'contain' }} />} onClose={() => setActiveImage(null)} />
          )
        }
      </SizeWindow>
      <Footer />
    </>
  )
}

export default GalleryPage
