import axios from 'axios'
import ErrorsHelper from '../ErrorsHelper/ErrorsHelper'

export const defaultHeaders = {
  'Access-Control-Allow-Origin': 'https://planszowki-koszalin.pl',
  'Content-Type': 'form-data',
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '0',
}

export default axios.create({
  baseURL: process.env.REACT_APP_URL_ALERTS,
  headers: {
    ...defaultHeaders,
  }
})

export const getMessageError = (status) => {
  return ErrorsHelper.checkErrorCode(status)
}
