import React from 'react'
import PropTypes from 'prop-types'
import './SimpleLayout.scss'
import MagicLoader from '../../components/atoms/MagicLoader/MagicLoader'

const SimpleLayout = (props) => {
  const Component = props.component
  const {location} = props
  const style = {
    root: {
      display: 'flex',
      overflow: 'hidden',
      height: '100vh',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    }
  }
  return (
    <div style={style.root}>
      <MagicLoader>
        <Component location={location}/>
      </MagicLoader>
    </div>
  )
}

SimpleLayout.defaultProps = {
  location: false
}

SimpleLayout.propsType = {
  location: PropTypes.object
}

export default SimpleLayout
