import ReactDOM from 'react-dom/client'
import App from './App'
import {createTheme, ThemeProvider} from '@mui/material/styles'
import ToastProvider from './components/organisms/ToastProvider/ToastProvider'

const root = ReactDOM.createRoot(document.getElementById('root'))

const error = console.error
console.error = (...args) => {
  if (/defaultProps/.test(args[0])) return
  error(...args)
}

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#2cbc63',
      black: '#000000',
      white: '#ffffff'
    },
    secondary: {
      main: '#2cbc63',
      second: '#023a51',
      grey: '#B5B5B5',
      greyLight: '#EBEBEB',
      greyDark: '#767373'
    },
    toasts: {
      success: '#2e7d32',
      warning: '#ed6c02',
      error: '#d32f2f',
      info: '#0288d1'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  typography: {
    useNextVariants: true
  },
  config: {
    maxToasts: 5,
    show: {
      gallery: false,
    },
    seo: {
      author: 'Your site, kontakt@your-site.pl',
      url: 'https://complexfinance.pl',
      website: 'complexfinance.pl',
      smallDescription: 'Księgowość to dla nas nie tylko cyfry. To historia Twojego sukcesu, którą pomagamy pisać.',
      name: 'Agnieszka Bochnia',
      email: 'biuro@complexfinance.pl',
      phone: '+48 600 988 801',
      company: 'Complex Finance',
      address: 'ul. Spółdzielcza 6A/1, 75-205 Koszalin',
      postalCode: '75-205',
      city: 'Koszalin',
      regin: 'Zachodniopomorskie',
      country: 'Polska',
      facebook: 'https://www.facebook.com/BiuroRachunkoweComplexFinance',
      youtube: 'https://www.youtube.com/@BiuroRachunkoweComplexFinance',
      instagram: '',
      hours: {
        ponPt: '8:00 - 16:00',
        sob: 'Nieczynne',
        nd: 'Nieczynne'
      },
      nip: '6692315354',
      regon: '362390905',
      pixel: '1065775408026004',
    },
  }
})

// eslint-disable-next-line jest/require-hook
root.render(
  <ToastProvider theme={theme}>
    <ThemeProvider theme={theme}>
      <App/>
    </ThemeProvider>
  </ToastProvider>
)
