import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import './AdminPage.scss'
import Typography from '@mui/material/Typography'
import {useEffect, useState} from 'react'
import {Grid} from '@mui/material'
import {useSnackbar} from 'notistack'
import Tabs from '../../components/molecules/Tabs/Tabs'
import {deleteCollectionById, getAllDataFromPath, getAllUsers, updateOrCreateDocument} from '../../firebase'
import BasicTable from '../../components/atoms/Table/Table'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import SeoHelper from '../../helpers/SeoHelper/SeoHelper'
import Seo from '../../components/molecules/Seo/Seo'

const AdminPage = (props) => {
  const {enqueueSnackbar} = useSnackbar()
  const [users, setUsers] = useState(null)
  const [usersItems, setUsersItems] = useState(null)
  const [dataTabs, setDataTabs] = useState([])
  const [tournaments, setTournaments] = useState(null)
  const [tags, setTags] = useState(null)
  const [categories, setCategories] = useState(null)
  const [articles, setArticles] = useState(null)
  const [galleries, setGalleries] = useState(null)
  const [seoData, setSeoData] = useState(null)
  const [silos, setSilos] = useState(null)
  const [silosItems, setSilosItems] = useState(null)
  const [silosItemsSmall, setSilosItemsSmall] = useState(null)
  const [checkSilos, setCheckSilos] = useState(null)
  const [checkCategories, setCheckCategories] = useState([])
  const [allArticlesItems, setAllArticlesItems] = useState([])
  const [articlesBasic, setArticlesBasic] = useState([])
  const [itemsTagsTournaments, setItemsTagsTournaments] = useState(null)
  const notificationType = props?.location?.location?.search?.replace('?', '').split('=')[0]
  const style = {
    root: {
      width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 110px)',
      padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px'
    },
    arrayNotExist: {
      fontSize: '16px',
      marginTop: '20px',
      color: '#324b54',
      background: '#ededed',
      padding: '20px',
      width: 'auto',
      borderRadius: '50px',
    }
  }
  const RenderElementToShow = ({ children, method, possibleToDelete = true, id, edit}) => {
    return (
      <Box
        sx={{
          display: 'flex',
          width: 'calc(100% - 40px)',
          padding: '20px',
          boxShadow: '2px 2px 5px 2px rgba(0,0,0,.1)',
          marginTop: '20px'
        }}
      >
        <Grid container spacing={4}>
          <Grid item md={12} xs={12}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              {children}
              <Box sx={{ display: 'flex' }}>
                {
                  edit && (
                    <Button sx={{ height: '40px', fontSize: '14px' }} variant="contained" onClick={() => edit?.method()}>{edit?.name}</Button>
                  )
                }
                {
                  possibleToDelete && (
                    <Button
                      sx={{ background: 'red', marginLeft: '20px', minWidth: '80px' }}
                      variant="contained"
                      onClick={() => method(id)}
                    >
                      Usuń
                    </Button>
                  )
                }
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    )
  }
  const updateUsers = () => {
    getAllUsers().then((res) => {
      if (res) {
        const tempData = []
        const tempDataItems = []
        res?.forEach(el => {
          const element = el?.data
          tempData.push({
            uid: element?.uid,
            email: element?.email,
            name: element?.name,
            phone: element?.phone,
            role: element?.role
          })
          tempDataItems.push({ value: element, name: element?.company ? element?.company : element?.name + ', ' + element?.email})
        })
        setUsers(tempData)
        setUsersItems(tempDataItems)
      }
    })
  }
  const updateTournaments = () => {
    getAllDataFromPath('tournaments').then((res) => {
      if (res) {
        setTournaments(res)
      }
    })
  }
  const updateTags = () => {
    getAllDataFromPath('tags').then((res) => {
      if (res) {
        res.sort((a, b) => {
          const fa = a?.data?.silos?.toLowerCase()
          const fb = b?.data?.silos?.toLowerCase()
          if (fa < fb) return -1
          if (fa > fb) return 1
          return 0
        })
        const tempItems = []
        res?.forEach(el => {
          tempItems.push({ name: el?.data?.name, value: el?.data?.name })
        })
        setItemsTagsTournaments(tempItems)
        setTags(res)
      }
    })
  }
  const updateCategories = () => {
    getAllDataFromPath('categories').then((res) => {
      if (res) {
        res.sort((a, b) => {
          const fa = a?.data?.silos?.toLowerCase()
          const fb = b?.data?.silos?.toLowerCase()
          if (fa < fb) return -1
          if (fa > fb) return 1
          return 0
        })
        setCategories(res)
      }
    })
  }
  const updateSilos = () => {
    getAllDataFromPath('silos').then((res) => {
      if (res) {
        setSilos(res)
      }
    })
  }
  const updateArticles = () => {
    getAllDataFromPath('articles').then((res) => {
      if (res) {
        setArticles(res)
      }
    })
  }
  const updateGaleries = () => {
    getAllDataFromPath('galleries').then((res) => {
      if (res) {
        setGalleries(res)
      }
    })
  }
  const updateSeo = () => {
    getAllDataFromPath('seo').then((res) => {
      if (res) {
        setSeoData(res)
      }
    })
  }
  const handleArticles = (e) => {
    updateOrCreateDocument('articles', null, e).then((res) => {
      if (res) {
        enqueueSnackbar('Poprawnie dodano artykuł.', {variant: 'success'})
        updateArticles()
      }
    })
  }
  const handleArticlesBasic = (e) => {
    updateOrCreateDocument('articlesBasic', 'config', e).then((res) => {
      if (res) {
        enqueueSnackbar('Poprawnie dodano artykuł.', {variant: 'success'})
        updateArticles()
      }
    })
  }
  const handleGalleries = (e) => {
    updateOrCreateDocument('galleries', null, e).then((res) => {
      if (res) {
        enqueueSnackbar('Poprawnie dodano kategorię galerii.', {variant: 'success'})
        updateGaleries()
      }
    })
  }
  const handleHomePageSeo = (e) => {
    updateOrCreateDocument('seo', 'HomePage', e).then((res) => {
      if (res) {
        enqueueSnackbar('Poprawnie zapisano seo.', {variant: 'success'})
        updateSeo()
      }
    })
  }
  const handleGalleryPageSeo = (e) => {
    updateOrCreateDocument('seo', 'GalleryPage', e).then((res) => {
      if (res) {
        enqueueSnackbar('Poprawnie zapisano seo.', {variant: 'success'})
        updateSeo()
      }
    })
  }
  const handleContactPageSeo = (e) => {
    updateOrCreateDocument('seo', 'ContactPage', e).then((res) => {
      if (res) {
        enqueueSnackbar('Poprawnie zapisano seo.', {variant: 'success'})
        updateSeo()
      }
    })
  }
  const handleTags = (e) => {
    updateOrCreateDocument('tags', null, e).then((res) => {
      if (res) {
        enqueueSnackbar('Poprawnie dodano tag, jest już dostępny przy budowie artykułów.', {variant: 'success'})
        updateTags()
      }
    })
  }
  const handleTournament = (e) => {
    updateOrCreateDocument('tournaments', null, e).then((res) => {
      if (res) {
        enqueueSnackbar('Poprawnie dodano zawody.', {variant: 'success'})
        updateTournaments()
      }
    })
  }
  const handleCategories = (e) => {
    updateOrCreateDocument('categories', null, e).then((res) => {
      if (res) {
        enqueueSnackbar('Poprawnie dodano kategorię.', {variant: 'success'})
        updateCategories()
      }
    })
  }
  const handleSilos = (e) => {
    updateOrCreateDocument('silos', null, e).then((res) => {
      if (res) {
        enqueueSnackbar('Poprawnie dodano silos.', {variant: 'success'})
        updateSilos()
      }
    })
  }
  const deleteTag = (id) => {
    deleteCollectionById('tags', id).then(() => {
      setTags(null)
      enqueueSnackbar('Poprawnie usunięto tag.', {variant: 'success'})
      updateTags()
    })
  }
  const deleteCategory = (id) => {
    deleteCollectionById('categories', id).then(() => {
      setCategories(null)
      enqueueSnackbar('Poprawnie usunięto kategorie.', {variant: 'success'})
      updateCategories()
    })
  }
  const deleteSilos = (id) => {
    deleteCollectionById('silos', id).then(() => {
      setSilos(null)
      enqueueSnackbar('Poprawnie usunięto silos.', {variant: 'success'})
      updateSilos()
    })
  }
  const deleteArticle = (id) => {
    deleteCollectionById('articles', id).then(() => {
      setSilos(null)
      enqueueSnackbar('Poprawnie usunięto artykuł.', {variant: 'success'})
      updateArticles()
    })
  }
  const deleteGallery = (id) => {
    deleteCollectionById('galleries', id).then(() => {
      setSilos(null)
      enqueueSnackbar('Poprawnie usunięto kategorię galerii.', {variant: 'success'})
      updateGaleries()
    })
  }
  const deleteTournament = (id) => {
    deleteCollectionById('tournaments', id).then(() => {
      setTournaments(null)
      enqueueSnackbar('Poprawnie usunięto zawody.', {variant: 'success'})
      updateTournaments()
    })
  }
  useEffect(() => {
    if (notificationType) {
      switch (notificationType) {
        case 'deleteImageFromGallery':
          enqueueSnackbar('Poprawnie usunięto zdjęcie z galerii.', {variant: 'success'})
          break
      }
    }
  }, [notificationType])
  useEffect(() => {
    updateUsers()
    updateTournaments()
    updateTags()
    updateCategories()
    updateArticles()
    updateSilos()
    updateSeo()
    updateGaleries()
  }, [])
  useEffect(() => {
    if (users && checkCategories) {
      const formData = {
        elements: [
          {
            name: 'link',
            label: 'Link do dysku',
            helperText: 'Uprawniania do linku, wszystkie osoby mające link, mają dostęp do folderu',
          },
          {
            name: 'person',
            label: 'Osoby mające dostęp',
            type: 'multiSelect',
            items: usersItems,
          },
          {
            type: 'button',
            value: 'Dodaj dane'
          }
        ]
      }
      const formDataTags = {
        elements: [
          {
            name: 'silos',
            type: 'select',
            label: 'Wybierz silos, z którego tag pochodzi',
            items: silosItems,
          },
          {
            name: 'name',
            label: 'Nazwa tagu',
            type: 'text',
          },
          {
            type: 'button',
            value: 'Dodaj tag',
          }
        ],
      }
      const checkTags = []
      if (tags && checkSilos) {
        tags?.forEach((el) => {
          if (el.data.silos === checkSilos) {
            checkTags.push({ value: el.id, name: el.data.name })
          }
        })
      }
      const formDataArticles = {
        elements: [
          {
            name: 'silos',
            type: 'select',
            label: 'Wybierz silos, z którego artykuł pochodzi',
            items: silosItemsSmall,
            onChange: (e) => setCheckSilos(e.target.value),
          },
          {
            name: 'category',
            label: 'Wybierz kategorie artykułu',
            type: 'select',
            items: checkCategories,
          },
        ],
      }
      const formDataGalleries = {
        elements: [
          {
            name: 'categoryName',
            type: 'text',
            label: 'Nazwa nowej kategorii',
          },
          {
            value: 'Zapisz kategorię galerii',
            type: 'button',
          }
        ],
      }
      const formDataHomePageSeo = {
        elements: []
      }
      SeoHelper.addFieldSeo(formDataHomePageSeo, seoData?.filter(el => el.id === 'HomePage'))
      formDataHomePageSeo.elements.push({
        type: 'button',
        value: 'Zapisz',
      })
      const formDataContactPageSeo = {
        elements: []
      }
      SeoHelper.addFieldSeo(formDataContactPageSeo, seoData?.filter(el => el.id === 'ContactPage'))
      formDataContactPageSeo.elements.push({
        type: 'button',
        value: 'Zapisz',
      })
      const formDataGalleryPageSeo = {
        elements: []
      }
      SeoHelper.addFieldSeo(formDataGalleryPageSeo, seoData?.filter(el => el.id === 'GalleryPage'))
      formDataGalleryPageSeo.elements.push({
        type: 'button',
        value: 'Zapisz',
      })
      const formDataArticlesBasic = {
        elements: [
          {
            name: 'category1',
            label: 'Wybierz artykuł 1',
            type: 'select',
            value: articlesBasic[0]?.data?.category1,
            items: allArticlesItems,
          },
          {
            name: 'category2',
            label: 'Wybierz artykuł 2',
            type: 'select',
            value: articlesBasic[0]?.data?.category2,
            items: allArticlesItems,
          },
          {
            name: 'category3',
            label: 'Wybierz artykuł 3',
            type: 'select',
            value: articlesBasic[0]?.data?.category3,
            items: allArticlesItems,
          },
          {
            name: 'category4',
            label: 'Wybierz artykuł 4',
            type: 'select',
            value: articlesBasic[0]?.data?.category4,
            items: allArticlesItems,
          },
          {
            type: 'button',
            value: 'Zapisz główne artykuły',
          }
        ],
      }
      if (checkTags?.length > 0) {
        formDataArticles.elements.push({
          name: 'tags',
          label: 'Wybierz tagi artykułu',
          type: 'multiSelect',
          items: checkTags,
        })
      }
      formDataArticles.elements.push({
          name: 'title',
          label: 'Tytuł artykułu',
          type: 'text',
        },
        {
          name: 'description',
          label: 'Opis artykułu',
          type: 'editor',
        })
      formDataArticles.elements.push({
        name: 'image',
        label: 'Zdjęcie główne',
        type: 'files',
        pathname: 'files',
      })
      SeoHelper.addFieldSeo(formDataArticles)
      formDataArticles.elements.push({
        type: 'button',
        value: 'Dodaj artykuł',
      })
      const formDataCategories = {
        elements: [
          {
            name: 'silos',
            type: 'select',
            label: 'Wybierz silos, z którego tag pochodzi',
            items: silosItems,
          },
          {
            name: 'name',
            label: 'Nazwa kategorii',
            type: 'text',
          },
          {
            name: 'description',
            label: 'Opis kategorii',
            type: 'editor',
          },
          {
            name: 'image',
            label: 'Zdjęcie główne',
            type: 'files',
            pathname: 'files',
          },
          {
            name: 'priceName',
            label: 'Nazwa usługi',
            type: 'text',
          },
          {
            name: 'priceData',
            label: 'Co jest w pakiecie',
            type: 'editor',
          },
          {
            name: 'price',
            label: 'Cena usługi',
            helperText: 'Podane w groszach',
            type: 'number',
          }
        ],
      }
      SeoHelper.addFieldSeo(formDataCategories)
      formDataCategories.elements.push({ type: 'button', value: 'Dodaj kategorię' })
      const formDataSilos = {
        elements: [
          {
            name: 'silos',
            type: 'select',
            label: 'Wybierz typ silosu',
            items: [{ name: 'menu', value: 'menu'}, { name: 'oferta', value: 'oferta'}, { name: 'blog', value: 'blog'}],
          },
          {
            name: 'name',
            label: 'Nazwa silosu',
            type: 'text',
          },
          {
            type: 'button',
            value: 'Dodaj silos',
          }
        ],
      }
      setDataTabs([
        {
          name: 'Użytkownicy',
          value: () => (
            <>
              {users && <BasicTable data={users} collection={'users'} callback={() => updateUsers()}/>}
            </>
          )
        },
        {
          name: 'Dane dla klienta',
          value: () => (
            <Grid container spacing={4}>
              <Grid item md={6} xs={12}>
                <Typography>Lista przekazanych danych</Typography>
                {
                  tournaments?.length > 0 ? tournaments?.map((el, index) => {
                    const data = el?.data
                    const id = el?.id
                    const children = (
                      <>
                        <Typography
                          sx={{
                            fontSize: '20px',
                            textTransform: 'uppercase',
                            fontWeight: '600'
                          }}
                        >
                          {data?.name}
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                          <Typography><b>Link:</b> {data?.link}</Typography>
                          <Typography><b>Osoby mające dostęp:</b> {data?.type}</Typography>
                          {
                            data?.person?.map((el, index) => {
                              return (
                                <Typography key={index}>
                                  {`${el?.company ? el?.company + ', ' : ''} ${el?.name || ''}`}
                                </Typography>
                              )
                            })
                          }
                        </Box>
                      </>
                    )
                    return <RenderElementToShow key={index} method={deleteTournament} id={id}>{children}</RenderElementToShow>
                  }) : (
                    <Typography sx={style.arrayNotExist}>Nie ma dostępnych danych.</Typography>
                  )
                }
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography>Dodaj nowe dane</Typography>
                <FormGenerator data={formData} submit={(e) => handleTournament(e)}/>
              </Grid>
            </Grid>
          )
        },
        {
          name: 'Silosy',
          value: () => (
            <Grid container spacing={4}>
              <Grid item md={7} xs={12}>
                <Typography>List dostępnych silosów</Typography>
                {
                  silos?.length ? silos?.map((el, index) => {
                    const data = el?.data
                    const id = el?.id
                    const children = (
                      <>
                        <Box>
                          <Typography sx={{ fontSize: '14px' }}>{data?.silos}</Typography>
                          <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>{data?.name}</Typography>
                        </Box>
                      </>
                    )
                    let checkIsPossible = true
                    categories?.forEach(el => {
                      const dataEl = el?.data
                      if (dataEl?.silos === data?.name) {
                        checkIsPossible = false
                      }
                    })
                    return <RenderElementToShow key={index} method={deleteSilos} edit={{ name: 'Edycja silosu', method: () => window.open(`/editSilos/${id}`, '_self')}} possibleToDelete={checkIsPossible} id={id}>{children}</RenderElementToShow>
                  }) : (
                    <Typography sx={{
                      fontSize: '16px',
                      marginTop: '20px',
                      color: '#324b54',
                      background: '#ededed',
                      padding: '20px',
                      width: 'auto',
                      borderRadius: '50px'
                    }}>Nie ma dostępnych silosów.</Typography>
                  )
                }
              </Grid>
              <Grid item md={5} xs={12}>
                <Typography>Tworzenie nowego silosu</Typography>
                <FormGenerator data={formDataSilos} submit={(e) => handleSilos(e)}/>
              </Grid>
            </Grid>
          )
        },
        {
          name: 'Kategorie',
          value: () => (
            <Grid container spacing={4}>
              <Grid item md={7} xs={12}>
                <Typography>List dostępnych kategorii</Typography>
                {
                  categories?.length ? categories?.map((el, index) => {
                    const data = el?.data
                    const id = el?.id
                    const children = (
                      <>
                        <Box>
                          <Typography sx={{ fontSize: '14px' }}>{data?.silos}</Typography>
                          <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>{data?.name}</Typography>
                        </Box>
                      </>
                    )
                    return <RenderElementToShow key={index} method={deleteCategory} edit={{ name: 'Edycja kategorii', method: () => window.open(`/editCategory/${id}`, '_self')}} id={id}>{children}</RenderElementToShow>
                  }) : <Typography sx={style.arrayNotExist}>Nie ma dostępnych kategorii.</Typography>
                }
              </Grid>
              <Grid item md={5} xs={12}>
                <Typography>Tworzenie nowej kategorii</Typography>
                {
                  silos?.length > 0 && (
                    <FormGenerator data={formDataCategories} submit={(e) => handleCategories(e)}/>
                  )
                }
              </Grid>
            </Grid>
          )
        },
        {
          name: 'Tagi',
          value: () => {
            return (
              <Grid container spacing={4}>
                <Grid item md={7} xs={12}>
                  <Typography>List dostępnych tagów</Typography>
                  {
                    tags?.length ? tags?.map((el, index) => {
                      const data = el?.data
                      const id = el?.id
                      const children = (
                        <>
                          <Box>
                            <Typography sx={{ fontSize: '14px' }}>{data?.silos}</Typography>
                            <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>{data?.name}</Typography>
                          </Box>
                        </>
                      )
                      return <RenderElementToShow key={index} method={deleteTag} edit={{ name: 'Edycja tagu', method: () => window.open(`/editTag/${id}`, '_self')}} id={id}>{children}</RenderElementToShow>
                    }) : (
                      <Typography sx={style.arrayNotExist}>Nie ma dostępnych tagów.</Typography>
                    )
                  }
                </Grid>
                <Grid item md={5} xs={12}>
                  <Typography>Tworzenie nowego tagu</Typography>
                  <FormGenerator data={formDataTags} submit={(e) => handleTags(e)}/>
                </Grid>
              </Grid>
            )
          }
        },
        {
          name: 'Artykuły',
          value: () => (
            <Grid container spacing={4}>
              <Grid item md={7} xs={12}>
                <Typography>List dostępnych artykułów</Typography>
                {
                  articles?.length ? articles?.map((el, index) => {
                    const data = el?.data
                    const id = el?.id
                    const children = (
                      <>
                        <Box>
                          <Typography sx={{ fontSize: '14px' }}>{data?.silos}</Typography>
                          <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>{data?.title}</Typography>
                        </Box>
                      </>
                    )
                    return <RenderElementToShow key={index} method={deleteArticle} edit={{ name: 'Edycja artykułu', method: () => window.open(`/editArticle/${id}`, '_self')}} id={id}>{children}</RenderElementToShow>
                  }) : (
                    <Typography sx={style.arrayNotExist}>Nie ma dostępnych artykułów.</Typography>
                  )
                }
              </Grid>
              <Grid item md={5} xs={12}>
                <Typography>Tworzenie nowego artykułu</Typography>
                {
                  checkCategories && <FormGenerator data={formDataArticles} submit={(e) => handleArticles(e)}/>
                }
              </Grid>
            </Grid>
          )
        },
        {
          name: 'Galeria',
          value: () => (
            <Grid container spacing={4}>
              <Grid item md={7} xs={12}>
                <Typography>Lista dostępnych kategorii galerii</Typography>
                {
                  galleries?.length ? galleries?.map((el, index) => {
                    const data = el?.data
                    const id = el?.id
                    const children = (
                      <>
                        <Box>
                          <Typography sx={{ fontSize: '14px' }}>Ilość zdjęć w galerii: {data?.images?.length || 0}</Typography>
                          <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>{data?.categoryName}</Typography>
                        </Box>
                      </>
                    )
                    return <RenderElementToShow key={index} method={deleteGallery} edit={{ name: 'Edycja zdjęć galerii', method: () => window.open(`/editGallery/${id}`, '_self')}} id={id}>{children}</RenderElementToShow>
                  }) : (
                    <Typography sx={style.arrayNotExist}>Nie ma dostępnych kategorii w galerii.</Typography>
                  )
                }
              </Grid>
              <Grid item md={5} xs={12}>
                <Typography>Tworzenie nowej kategorii galerii</Typography>
                {
                  checkCategories && <FormGenerator data={formDataGalleries} submit={(e) => handleGalleries(e)}/>
                }
              </Grid>
            </Grid>
          )
        },
        {
          name: 'Wybierz artykuły główne',
          value: () => (
            <Grid container spacing={4}>
              <Grid item md={6} xs={12}>
                <Typography>Ustawianie artykułów głównych</Typography>
                {
                  checkCategories && <FormGenerator data={formDataArticlesBasic} submit={(e) => handleArticlesBasic(e)}/>
                }
              </Grid>
            </Grid>
          )
        },
        {
          name: 'Seo stron statycznych',
          value: () => (
            <Grid container spacing={4}>
              <Grid item md={6} xs={12}>
                <Typography>Strona Główna</Typography>
                <FormGenerator data={formDataHomePageSeo} submit={(e) => handleHomePageSeo(e)}/>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography>Strona Galerii</Typography>
                <FormGenerator data={formDataGalleryPageSeo} submit={(e) => handleGalleryPageSeo(e)}/>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography>Strona Kontaktu</Typography>
                <FormGenerator data={formDataContactPageSeo} submit={(e) => handleContactPageSeo(e)}/>
              </Grid>
            </Grid>
          )
        },
      ])
    }
  }, [users, tournaments, tags, categories, silos, silosItems, articles, checkCategories, galleries, silosItemsSmall])
  useEffect(() => {
    if (silos?.length > 0) {
      const temp = []
      const tempSmall = []
      silos.forEach(el => {
        const data = el?.data
        let isAdd = false
        categories.forEach((element) => {
          if (element.data.silos === el.data.name) {
            isAdd = true
          }
        })
        if (isAdd) {
          tempSmall.push({ value: data?.name, name: data?.name })
        }
        temp.push({ value: data?.name, name: data?.name })
      })
      setSilosItems(temp)
      setSilosItemsSmall(tempSmall)
    }
  }, [silos])
  useEffect(() => {
    if (checkSilos && categories) {
      const tempCat = []
      categories.forEach((el) => {
        if (el.data.silos === checkSilos) {
          tempCat.push({ value: el.id, name: el.data.name })
        }
      })
      setCheckCategories(tempCat)
    }
  }, [checkSilos, categories])
  useEffect(() => {
    if (articlesBasic) {
      getAllDataFromPath('articles').then((res) => {
        if (res) {
          const tempCatBasic = []
          res.forEach(el => {
            tempCatBasic.push({ value: el.id, name: el.data.title })
          })
          setAllArticlesItems(tempCatBasic)
        }
      })
    }
  }, [checkSilos, categories, articlesBasic])
  useEffect(() => {
    getAllDataFromPath('articlesBasic').then((res) => {
      if (res) {
        setArticlesBasic(res)
      }
    })
  }, [])
  return (
    <>
      <Seo data={{ 'seo: title': 'Complex Finance - Panel administracyjny' }}/>
      <div style={style.root}>
        <Grid container spacing={8}>
          <Grid item md={12} xs={12}>
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '30px'
              }}
            >
              Panel administracyjny
            </Typography>
          </Grid>
          <Grid item md={12} xs={12}>
            {
              dataTabs && (
                <Tabs data={dataTabs}/>
              )
            }
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default AdminPage
