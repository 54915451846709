import {useEffect, useState} from 'react'
import {getAllDataFromPath} from '../../../firebase'
import Box from '@mui/material/Box'
import {Grid} from '@mui/material'

const Tags = ({ data }) => {
  const tagsData = data?.tags
  const [listTags, setListTags] = useState()
  useEffect(() => {
    if (tagsData) {
      getAllDataFromPath('tags').then((res) => {
        if (res) {
          const temp = []
          res?.forEach(el => {
            const data = el?.data
            tagsData?.forEach(tag => {
              if (el?.id === tag) {
                temp.push(data?.name)
              }
            })
          })
          setListTags(temp)
        }
      })
    }
  }, [tagsData])
  return (
    <Box sx={{ marginTop: '20px' }}>
      <Grid container spacing={1}>
        {
          listTags?.map((el, index) => {
            return (
              <Grid item key={index}>
                <Box sx={{ background: 'white', padding: '5px 15px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px' }}>
                  {el}
                </Box>
              </Grid>
            )
          })
        }
      </Grid>
    </Box>
  )
}

export default Tags
