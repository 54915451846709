import Typography from '@mui/material/Typography'
import Page from '../../components/molecules/Page/Page'
import Footer from '../../components/molecules/Footer/Footer'
import Seo from '../../components/molecules/Seo/Seo'
import PriceImage from '../../assets/images/Zdjęcia (2).png'
import {Grid} from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import Dot from '../../components/atoms/Dot/Dot'

const PricesPage = () => {
  const style = {
    small: {
      fontSize: BreakpointsHelper.isMobile() ? '30px' : '52px',
      fontWeight: '600',
      textAlign: 'center',
      maxWidth: '1024px',
      margin: '0 auto',
      marginBottom: '50px',
    },
  }
  return (
    <>
      <Seo data={{ 'seo: title': 'Complex Finance - Cennik' }}/>
      <Page
        section2Center
        image={PriceImage}
        title={'Cennik'}
        small={'Każdy przedsiębiorca ma inne potrzeby i oczekiwania'}
        description={(
          <>
            <Typography sx={{ fontSize: '22px' }}>
              Zleć indywidualną wycenę usługi poprzez formularz zgłoszeniowy „<b>Darmowa wycena</b>”, wyślij wiadomość e-mail lub
              skontaktuj się z nami telefonicznie.
            </Typography>
              <Button
                  onClick={() => window.open('/contact', '_self')}
                  variant="contained"
                  sx={{ marginRight: '20px', color: 'white', borderRadius: '20px !important', height: '46px', marginTop: '30px', width: BreakpointsHelper.isMobile() ? '100%' : 'auto' }}
              >
                  Darmowa wycena dla Ciebie
              </Button>
          </>
        )}
        description2={(
          <Box sx={{ padding: BreakpointsHelper.isMobile() ? '0' : '150px' }}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <Typography sx={style.small}>
                  Ceny minimalne dostępnych usług księgowych oraz kadrowo-płacowych<Dot/>
                </Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', background: 'white', borderRadius: '30px', padding: '30px' }}>
                  <Typography sx={{ fontSize: '22px', fontWeight: '600' }}>Ryczałt</Typography>
                  <Typography sx={{ fontSize: '22px', fontWeight: '400' }}>od 250.00 zł</Typography>
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', background: 'white', borderRadius: '30px', padding: '30px' }}>
                  <Typography sx={{ fontSize: '22px', fontWeight: '600' }}>PKPiR</Typography>
                  <Typography sx={{ fontSize: '22px', fontWeight: '400' }}>od 250.00 zł</Typography>
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', background: 'white', borderRadius: '30px', padding: '30px' }}>
                  <Typography sx={{ fontSize: '22px', fontWeight: '600' }}>Księgi handlowe</Typography>
                  <Typography sx={{ fontSize: '22px', fontWeight: '400' }}>od 800.00 zł</Typography>
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', background: 'white', borderRadius: '30px', padding: '30px' }}>
                  <Typography sx={{ fontSize: '22px', fontWeight: '600' }}>Kadry i płace</Typography>
                  <Typography sx={{ fontSize: '22px', fontWeight: '400' }}>od 60.00 zł / os.</Typography>
                </Box>
              </Grid>
            </Grid>
            <Typography sx={{ marginTop: '50px' }}>
              Pozostałe usługi dostępne w zakładce „Oferta” wyceniane są indywidualnie.
            </Typography>
            <Typography>
              Powyższe ceny są cenami netto i należy do nich doliczyć podatek VAT w wysokości 23%.
            </Typography>
          </Box>
        )}
      />
      <Footer/>
    </>
  )
}

export default PricesPage
