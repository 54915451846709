import Seo from '../../components/molecules/Seo/Seo'
import {Grid} from '@mui/material'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import {getAllDataFromPath, updateOrCreateDocument} from '../../firebase'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import MagicLoader from '../../components/atoms/MagicLoader/MagicLoader'
import {useTheme} from '@mui/styles'

const EditSilosPage = () => {
    const theme = useTheme()
    const idElement = window.location.pathname.split('/')[2]
    const {enqueueSnackbar} = useSnackbar()
    const style = {
        root: {
            width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 110px)',
            padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px'
        },
        arrayNotExist: {
            fontSize: '16px',
            marginTop: '20px',
            color: '#324b54',
            background: '#ededed',
            padding: '20px',
            width: 'auto',
            borderRadius: '50px',
        }
    }
    const [formDataSilos, setFormDataSilos] = useState({})
    const handleSubmit = (e) => {
        updateOrCreateDocument('silos', idElement, e).then((res) => {
            if (res) {
                enqueueSnackbar('Zapisano dane poprawnie.', {variant: 'success'})
            }
        })
    }
    useEffect(() => {
        getAllDataFromPath('silos').then((res) => {
            if (res) {
                res?.forEach(el => {
                    if (el?.id === idElement) {
                        setFormDataSilos({
                            elements: [
                                {
                                    name: 'silos',
                                    type: 'select',
                                    label: 'Wybierz typ silosu',
                                    value: el?.data?.silos,
                                    items: [{name: 'menu', value: 'menu'}, {
                                        name: 'oferta',
                                        value: 'oferta'
                                    }, {name: 'blog', value: 'blog'}],
                                },
                                {
                                    name: 'name',
                                    label: 'Nazwa silosu',
                                    value: el?.data?.name,
                                    type: 'text',
                                },
                                {
                                    type: 'button',
                                    value: 'Dodaj silos',
                                }
                            ],
                        })
                    }
                })
            }
        })
    }, [])
    return (
        <>
            <Seo data={{'seo: title': `${theme?.config?.seo?.company} - Panel administracyjny`}}/>
            <MagicLoader variable={formDataSilos?.elements?.length > 0}>
                <div style={style.root}>
                    <Grid container spacing={8}>
                        <Grid item md={12} xs={12}>
                            <FormGenerator data={formDataSilos} submit={(e) => handleSubmit(e)}/>
                        </Grid>
                    </Grid>
                </div>
            </MagicLoader>
        </>
    )
}

export default EditSilosPage
