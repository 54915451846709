import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material'
import Box from '@mui/material/Box'
import { useHistory } from 'react-router'

const SkeletonLoader = (props) => {
  const history = useHistory()
  const { count, children, callback, padding, variable = true, time, circle, height } = props
  const [isLoading, setIsLoading] = useState(true)
  const { pathname, search } = history
  useEffect(() => {
    let timeLoad = null
    setIsLoading(true)
    if (!callback) {
      timeLoad = setTimeout(() => {
        if (variable) setIsLoading(false)
      }, time || 500)
    } else {
      callback(setIsLoading)
    }
    return () => clearTimeout(timeLoad)
  }, [variable, pathname, search])
  return (
      <SkeletonTheme baseColor="#e0dcdc" highlightColor="#444">
        {
          isLoading
? (
              <div style={{ marginTop: count === 1 ? '0' : '20px' }}>
                {
                  circle
? (
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: height || '100vh' }}>
                        <CircularProgress sx={{ color: '#2a3270' }} />
                      </Box>
                    )
: (
                    <Skeleton
                      style={{
                        marginBottom: '15px',
                        marginLeft: padding && '10px',
                        marginRight: padding && '10px',
                        width: padding ? 'calc(100% - 20px)' : '100%',
                      }}
                      minrows={5}
                      enableAnimation
                      duration={100}
                      direction="ltr"
                      height={count === 1 ? '100%' : 15}
                      count={count}
                    />
                  )
                }
              </div>
          )
: children
        }
      </SkeletonTheme>
  )
}

export default SkeletonLoader
