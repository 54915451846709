import {BrowserRouter as Router, Switch} from 'react-router-dom'
import MainLayout from './layouts/MainLayout/MainLayout'
import {Redirect} from 'react-router'
import './assets/scss/App.scss'
import {PublicRoute} from './router/PublicRoute/PublicRoute'
import LoginPage from './pages/LoginPage/LoginPage'
import SimpleLayout from './layouts/SimpleLayout/SimpleLayout'
import {PrivateRoute} from './router/PrivateRoute/PrivateRoute'
import HomePage from './pages/HomePage/HomePage'
import PreviewLayout from './layouts/PreviewLayout/PreviewLayout'
import RegisterPage from './pages/RegisterPage/RegisterPage'
import ProfilePage from './pages/ProfilePage/ProfilePage'
import AdminPage from './pages/AdminPage/AdminPage'
import RememberPage from './pages/RememberPage/RememberPage'
import LangHelper from './helpers/LangHelper/LangHelper'
import DashboardPage from './pages/DashboardPage/DashboardPage'
import PreviewArticle from './pages/PreviewArticlePage/PreviewArticlePage'
import ContactPage from './pages/ContactPage/ContactPage'
import ShowCategoriesPage from './pages/ShowCategories/ShowCategoriesPage'
import PolicyPage from './pages/PolicyPage/PolicyPage'
import GalleryPage from './pages/GalleryPage/GalleryPage'
import EditSilosPage from './pages/EditSilosPage/EditSilosPage'
import EditCategoryPage from './pages/EditCategoryPage/EditCategoryPage'
import EditTagPage from './pages/EditTagPage/EditTagPage'
import EditArticlePage from './pages/EditArticlePage/EditArticlePage'
import EditGalleryPage from './pages/EditGalleryPage/EditGalleryPage'
import RegulationPage from './pages/RegulationPage/RegulationPage'
import AboutPage from './pages/AboutPage/AboutPage'
import BookkeepingPage from './pages/BookkeepingPage/BookkeepingPage'
import PricesPage from './pages/PricesPage/PricesPage'
import HrAndPayrollPage from './pages/HrAndPayrollPage/HrAndPayrollPage'
import ControllingPage from './pages/ControllingPage/ControllingPage'
import ManagementServicesPage from './pages/ManagementServicesPage/ManagementServicesPage'
import DueDiligencePage from './pages/DueDiligencePage/DueDiligencePage'
import BusinessAndEconomicConsultingPage
  from './pages/BusinessAndEconomicConsultingPage/BusinessAndEconomicConsultingPage'

const App = () => {
  LangHelper.setDefaultLanguage()
  return (
    <Router basename={'/'}>
      <Switch>
        <PublicRoute exact path={'/'} component={() => <Redirect to={'/home'}/>} layout={SimpleLayout}/>
        <PublicRoute path={'/home'} component={HomePage} layout={PreviewLayout}/>
        <PublicRoute path={'/about'} component={AboutPage} layout={PreviewLayout}/>
        <PublicRoute path={'/bookkeeping'} component={BookkeepingPage} layout={PreviewLayout}/>
        <PublicRoute path={'/prices'} component={PricesPage} layout={PreviewLayout}/>
        <PublicRoute path={'/controlling'} component={ControllingPage} layout={PreviewLayout}/>
        <PublicRoute path={'/managementServices'} component={ManagementServicesPage} layout={PreviewLayout}/>
        <PublicRoute path={'/dueDiligence'} component={DueDiligencePage} layout={PreviewLayout}/>
        <PublicRoute path={'/business'} component={BusinessAndEconomicConsultingPage} layout={PreviewLayout}/>
        <PublicRoute path={'/hr'} component={HrAndPayrollPage} layout={PreviewLayout}/>
        <PublicRoute path={'/login'} component={LoginPage} layout={SimpleLayout}/>
        <PublicRoute path={'/register'} component={RegisterPage} layout={SimpleLayout}/>
        <PublicRoute path={'/reset'} component={RememberPage} layout={SimpleLayout}/>
        <PublicRoute path={'/policy'} component={PolicyPage} layout={PreviewLayout}/>
        <PublicRoute path={'/regulation'} component={RegulationPage} layout={PreviewLayout}/>
        <PublicRoute path={'/gallery'} component={GalleryPage} layout={PreviewLayout}/>
        <PublicRoute path={'/showArticle/:id'} component={PreviewArticle} layout={PreviewLayout}/>
        <PublicRoute path={'/showCategories/:id'} component={ShowCategoriesPage} layout={PreviewLayout}/>
        <PublicRoute path={'/contact'} component={ContactPage} layout={PreviewLayout}/>
        <PrivateRoute path={'/admin'} component={AdminPage} layout={MainLayout}/>
        <PrivateRoute path={'/dashboard'} component={DashboardPage} layout={MainLayout}/>
        <PrivateRoute path={'/profile'} component={ProfilePage} layout={MainLayout}/>
        <PrivateRoute path={'/editSilos/:id'} component={EditSilosPage} layout={MainLayout}/>
        <PrivateRoute path={'/editCategory/:id'} component={EditCategoryPage} layout={MainLayout}/>
        <PrivateRoute path={'/editTag/:id'} component={EditTagPage} layout={MainLayout}/>
        <PrivateRoute path={'/editArticle/:id'} component={EditArticlePage} layout={MainLayout}/>
        <PrivateRoute path={'/editGallery/:id'} component={EditGalleryPage} layout={MainLayout}/>
      </Switch>
    </Router>
  )
}

export default App
