import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import Box from '@mui/material/Box'
import {Grid, Link, useTheme} from '@mui/material'
import logo from '../../../assets/configFiles/logo.svg'
import Typography from '@mui/material/Typography'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'
import regulations from '../../../assets/configFiles/regulations.pdf'
import policy from '../../../assets/configFiles/policy.pdf'

const Footer = () => {
  const t = LocalStorageHelper.get('translation')
  const theme = useTheme()
  const { company, phone, email, address, facebook, youtube, instagram, hours, smallDescription } = theme.config.seo
  const style = {
    h2: {
      fontSize: '38px',
      fontWeight: '800',
      textAlign: 'left',
      marginTop: '10px',
      width: '100%'
    },
    h3: {
      fontSize: '22px',
      fontWeight: '900',
      marginTop: '10px',
      textAlign: 'left',
      width: '100%',
      marginBottom: '20px',
    },
    image: {
      width: '100%',
      display: 'flex',
      maxWidth: '320px',
      marginBottom: '30px'
    }
  }
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: BreakpointsHelper.isMobile() ? 'flex-start' : 'center',
        flexDirection: BreakpointsHelper.isMobile() && 'column',
        padding: BreakpointsHelper.isMobile() ? '0 30px' : '0 100px',
        width: BreakpointsHelper.isMobile() ? 'calc(100% - 60px)' : 'calc(100% - 200px)',
        borderTop: '1px solid rgb(229,231,235)',
        marginTop: '100px',
      }}
    >
      <Box sx={{maxWidth: '1920px', display: 'flex', alignItems: 'flex-start', padding: '50px 0 0 0'}}>
        <Grid container spacing={BreakpointsHelper.isMobile() ? 0 : 8}>
          <Grid item md={4} xs={12}>
            <img src={logo} alt="logo" style={style.image}/>
            {
              smallDescription && (
                <Typography variant="p" sx={{width: '100%'}}>
                  {smallDescription}
                </Typography>
              )
            }
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography className="bold" variant="h2" sx={style.h3}>
              Informacje podstawowe
            </Typography>
            <Typography sx={{ marginTop: '10px' }}><b>Telefon: </b><Link href={`tel:${phone?.replace(' ', '')?.replace(' ', '')?.replace(' ', '')}`}>{phone}</Link>
            </Typography>
            <Typography><b>Email: </b><Link href={`mailto:${email}`}>{email}</Link></Typography>
            <Typography><b>Adres: </b><Link href={`https://www.google.com/maps/place/${address}/@54.1953834,16.1894642,17z/data=!3m1!4b1!4m5!3m4!1s0x4701cd13bbf74b71:0x5b45fac2e58fc705!8m2!3d54.1953803!4d16.1920445?entry=ttu`}>{address}</Link>
            </Typography>
            <Typography sx={{ marginTop: '10px' }}><b>Godziny otwarcia:</b></Typography>
            <Typography>Pon - Pt: {hours.ponPt}</Typography>
            <Typography>Sobota: {hours.sob}</Typography>
            <Typography>Niedziela: {hours.nd}</Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography className="bold" variant="h2" sx={style.h3}>
              Informacje biznesowe
            </Typography>
            <Typography><a href={'/regulation'} target="_self">Regulamin serwisu</a></Typography>
            <Typography><a href={'/policy'} target="_self">Polityka prywatności</a></Typography>
            { facebook && <Typography><a href={facebook} target="_blank" rel="noreferrer">Facebook</a></Typography> }
            { instagram && <Typography><a href={instagram} target="_blank" rel="noreferrer">Instagram</a></Typography> }
            { youtube && <Typography><a href={youtube} target="_blank" rel="noreferrer">Youtube</a></Typography> }
          </Grid>
          <Grid item md={12} xs={12}>
            <Typography sx={{marginBottom: '20px'}}>
              @ {new Date().getFullYear()} wszelkie prawa zastrzeżone przez {company}. Strona stworzona
              przez &nbsp;
              <a href="https://your-site.pl" target="_blank" rel="noreferrer">Your&nbsp;site</a>.
              Wszelkie informacje dostępne pod:
              <a href={regulations} target="_blank" rel="noreferrer">{t?.homePage?.footer.desc2}</a>
              &nbsp;
              <a href={policy} target="_blank" rel="noreferrer">{t?.homePage?.footer.desc3}</a>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default Footer
