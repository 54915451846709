import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Dot from '../../atoms/Dot/Dot'
import SizeWindow from '../../atoms/SizeWindow/SizeWindow'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import {Grid} from '@mui/material'

const Page = ({ title, description, description2, small, image, image2, revert, section2Center, centerHeading }) => {
  const style = {
    root: {
      padding: BreakpointsHelper.isMobile() ? '50px 0' : '100px 0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
    },
    title: {
      fontSize: '30px',
      fontWeight: '600',
      color: '#2cbc63',
      marginBottom: '10px',
      textAlign: image ? 'left' : 'center',
    },
    small: {
      fontSize: BreakpointsHelper.isMobile() ? '31px' : '52px',
      fontWeight: '600',
      marginBottom: '30px',
      maxWidth: '720px',
      textAlign: centerHeading ? 'center' : image ? 'left' : 'center',
      margin: centerHeading ? '0 auto' : '0 auto 20px 0'
    },
    description: {
      width: '100%',
      fontSize: '22px',
      '> *': {
        marginBottom: '20px !important',
      }
    },
    img: {
      width: '100%',
      borderRadius: '50px',
      marginBottom: '50px',
      minHeight: BreakpointsHelper.isMobile() ? 'none' : '480px',
      objectFit: 'cover'
    }
  }
  return (
    <SizeWindow>
      <Box sx={style.root}>
        <Grid container spacing={BreakpointsHelper.isMobile() ? 0 : 8}>
          {
            revert ? (
                 <>
                   {
                     image && (
                       <Grid item md={6} xs={12}>
                         <img src={image} alt="image" style={style.img} />
                       </Grid>
                     )
                   }
                   <Grid item md={6} xs={12}>
                     {
                       title && (
                         <Typography variant="h1" sx={style.title}>{title}</Typography>
                       )
                     }
                     {
                       small && (
                         <Typography variant="h2" sx={style.small}>{small}<Dot /></Typography>
                       )
                     }
                     <Typography sx={style.description}>{description}</Typography>
                   </Grid>
                 </>
            ) : (
              <>
                {
                  BreakpointsHelper.isMobile() ? (
                    <>
                      {
                          image && (
                              <Grid item md={6} xs={12}>
                                <img src={image} alt="image" style={style.img} />
                              </Grid>
                          )
                      }
                      <Grid item md={image ? 6 : 12} xs={12}>
                        {
                            title && (
                                <Typography variant="h1" sx={style.title}>{title}</Typography>
                            )
                        }
                        {
                            small && (
                                <Typography variant="h2" sx={style.small}>{small}<Dot /></Typography>
                            )
                        }
                        <Typography sx={style.description}>{description}</Typography>
                      </Grid>
                    </>
                  ) : (
                      <>
                        <Grid item md={image ? 6 : 12} xs={12}>
                          {
                              title && (
                                  <Typography variant="h1" sx={style.title}>{title}</Typography>
                              )
                          }
                          {
                              small && (
                                  <Typography variant="h2" sx={style.small}>{small}<Dot /></Typography>
                              )
                          }
                          <Typography sx={style.description}>{description}</Typography>
                        </Grid>
                        {
                            image && (
                                <Grid item md={6} xs={12}>
                                  <img src={image} alt="image" style={style.img} />
                                </Grid>
                            )
                        }
                      </>
                  )
                }
              </>
            )
          }
          {
            !section2Center && (
              <Grid item md={6} xs={12}>
                {
                  image2 && (
                    <img src={image2} alt="image" style={style.img} />
                  )
                }
              </Grid>
            )
          }
          <Grid item md={!section2Center ? 6 : 12} xs={12}>
            <Typography sx={style.description}>{description2}</Typography>
          </Grid>
        </Grid>
      </Box>
    </SizeWindow>
  )
}

export default Page
