import Footer from '../../components/molecules/Footer/Footer'
import Page from '../../components/molecules/Page/Page'
import Typography from '@mui/material/Typography'
import Seo from '../../components/molecules/Seo/Seo'
import HrImage from '../../assets/images/old/41.png'
import HrImage2 from '../../assets/images/old/42.png'
import ContactBanner from '../../components/molecules/ContactBanner/ContactBanner'
import Button from '@mui/material/Button'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'

const HrAndPayrollPage = () => {
  const style = {
    desc: {
      '> *': {
        fontSize: '22px',
        fontWeight: '600',
      },
      fontSize: '22px',
    },
    titleBox: {
      fontSize: '22px',
      textAlign: 'left',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginBottom: '10px',
    },
    img: {
      fill: '#2cbc63',
      width: '40px',
      marginRight: '15px',
    }
  }
  return (
    <>
      <Seo data={{ 'seo: title': 'Complex Finance - Kadry i płace' }}/>
      <Page
        image={HrImage}
        title={'Kadry i płace'}
        small={'Sprawy kadrowo-płacowe w dobrych rękach'}
        description={(
          <>
            <Typography sx={style.desc}>
              W <b>Complex Finance</b> rozumiemy, jak kluczowe dla efektywnego funkcjonowania
              każdej firmy są sprawy kadrowe i płacowe. Dlatego oferujemy kompleksową
              obsługę kadrowo-płacową, a tym samym - zapewniamy spokój i
              bezpieczeństwo zarówno pracodawcom, jak i pracownikom. Nasze usługi
              zostały stworzone, aby <b>odciążyć Cię</b> od skomplikowanych i
              czasochłonnych zadań związanych z zarządzaniem personelem.
            </Typography>
              <Button
                  onClick={() => window.open('/contact', '_self')}
                  variant="contained"
                  sx={{ marginRight: '20px', color: 'white', borderRadius: '20px !important', height: '46px', marginTop: '30px', width: BreakpointsHelper.isMobile() ? '100%' : 'auto' }}
              >
                  Skontaktuj się z nami
              </Button>
          </>
        )}
      />
      <Page
        revert
        image={HrImage2}
        title={'Kadry i płace'}
        small={'Co wchodzi w skład naszej oferty'}
        description={(
          <>
            <ul style={{ marginTop: '50px' }}>
              <li>Cyfryzacja dokumentacji pracowniczej, zapewniająca łatwy dostęp i zarządzanie aktami osobowymi.</li>
              <li>Profesjonalne przygotowanie umów o pracę, zlecenie i dzieło - dostosowane do potrzeb Twojej firmy i specyfiki stanowiska.</li>
              <li>Sporządzanie list płac - precyzyjne i terminowe rozliczanie wynagrodzeń pracowników.</li>
              <li>Sporządzanie świadectw pracy - dokumentacja zakończenia współpracy z pracownikiem, zgodnie z obowiązującymi przepisami.</li>
              <li>Rozliczanie czasu pracy i absencji pracowników - zarządzanie ewidencją czasu pracy, urlopów i nieobecności.</li>
              <li>Opracowywanie regulaminów pracy i wynagradzania - tworzenie wewnętrznych dokumentów firmy, regulujących najistotniejsze kwestie.</li>
              <li>Wystawianie skierowań na badania</li>
              <li>Kompleksowa obsługa zgłoszeń ubezpieczeniowych i podatkowych.</li>
              <li>Reprezentacja klienta przed organami kontroli z US, ZUS i Państwową Inspekcją Pracy (PIP) - w przypadku kontroli i audytów.</li>
              <li>Wystawianie zaświadczeń o zarobkach - przygotowanie dokumentów potwierdzających poziom dochodów pracowników.</li>
              <li>Przygotowywanie deklaracji ZUS, PIT-11 i PIT-4R - dokładne i terminowe rozliczenia z zakresu podatków i ubezpieczeń społecznych.</li>
            </ul>
          </>
        )}
      />
      <ContactBanner />
      <Footer />
    </>
  )
}

export default HrAndPayrollPage
