import favicon from '../../../assets/configFiles/favicon.png'
import {Helmet} from 'react-helmet'
import {useTheme} from '@mui/material'
import {useEffect} from 'react'

const Seo = ({data}) => {
  const theme = useTheme()
  const seo = theme.config.seo
  useEffect(() => {
    !(function(f, b, e, v, n, t, s) {
      if (f.fbq) return
      n = f.fbq = function() {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      }
      if (!f._fbq) f._fbq = n
      n.push = n
      n.loaded = !0
      n.version = '2.0'
      n.queue = []
      t = b.createElement(e)
      t.async = !0
      t.src = v
      s = b.getElementsByTagName(e)[0]
      s.parentNode.insertBefore(t, s)
    }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js'))
    fbq('init', '1065775408026004')
    fbq('track', 'PageView')
  }, [])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{`${data['seo: title'] || seo?.title || ''} ${((data['seo: prase']?.length > 0 && data['seo: prase']) || data['seo: page']) ? '-' : ''} ${data['seo: prase'] || data['seo: page'] || ''}`}</title>
        <link rel="icon" href={favicon}/>
        <meta name="keywords" content={data['seo: keywords'] || ''}/>
        <meta name="description" content={data['seo: description'] || ''}/>
        {seo?.name && <meta name="subject" content={seo.name}/>}
        {seo?.author && <meta name="copyright" content={seo.author}/>}
        <meta name="language" content="PL"/>
        <meta name="robots" content="index,follow"/>
        {seo?.author && <meta name="author" content={seo.author}/>}
        {seo?.author && <meta name="designer" content={seo.author}/>}
        {seo?.author && <meta name="copyright" content={seo.author}/>}
        {seo?.author && <meta name="owner" content={seo.author}/>}
        {seo?.url && <meta name="url" content={seo.url}/>}
        {seo?.url && <meta name="identifier-URL" content={seo.url}/>}
        <meta name="category" content={data['seo: category'] || ''}/>
        <meta name="distribution" content="Global"/>
        <meta name="rating" content="General"/>
        <meta name="revisit-after" content="7 days"/>
        <meta httpEquiv="Expires" content="0"/>
        <meta httpEquiv="Pragma" content="no-cache"/>
        <meta httpEquiv="Cache-Control" content="no-cache"/>
        <meta name="og:title" content={data['seo: ogTitle'] || ''}/>
        <meta name="og:type" content="website"/>
        <meta name="og:url" content={seo.url}/>
        <meta name="og:description" content={data['seo: ogDescription'] || ''}/>
        <meta name="fb:page_id" content={data['seo: fbPageId'] || ''}/>
        <meta name="og:email" content={seo.email}/>
        <meta name="og:image" content={data['seo: ogImage'] || ''}/>
        <meta name="og:phone_number" content={seo.phone}/>
        <meta name="og:locality" content={seo.city}/>
        <meta name="og:locale" content="pl_PL"/>
        <meta name="og:region" content={seo.region}/>
        <meta name="og:site_name" content={data['seo: ogTitle'] || ''}/>
        <meta name="og:postal-code" content={seo.postalCode}/>
        <meta name="og:country-name" content={seo.country}/>
        <meta property="og:type" content="website"/>
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:title" content={data['seo: twitterTitle'] || ''}/>
        <meta property="twitter:description" content={data['seo: twitterDescription'] || ''}/>
        <meta property="twitter:image" content={data['seo: twitterImage'] || ''}/>
        <meta property="twitter:label1" content="Napisane przez"/>
        <meta property="twitter:data1" content={data['seo: twitterId'] || ''}/>
        <meta property="twitter:label2" content="Czas czytania"/>
        <meta property="twitter:data2" content="6 minut/y"/>
        <meta name="apple-mobile-web-app-capable" content="yes"/>
        <meta content="yes" name="apple-touch-fullscreen"/>
        <meta name="apple-mobile-web-app-status-bar-style" content="black"/>
        <meta name="format-detection" content="telephone=no"/>
      </Helmet>
    </>
  )
}

export default Seo
