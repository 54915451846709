import {Button, Grid} from '@mui/material'
import Box from '@mui/material/Box'
import AboutImage from '../../../assets/images/old/Zdjęcia (4).png'
import Typography from '@mui/material/Typography'
import Dot from '../Dot/Dot'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'

const Slider = () => {
    const style = {
        root: {
            display: 'flex',
            alignItems: 'center',
            padding: '100px 0'
        },
        image: {
            maxWidth: '100%',
            width: 'auto',
            borderRadius: '50px',
            maxHeight: '600px',
            minHeight: BreakpointsHelper.isMobile() ? 'none' : '480px',
            objectFit: 'cover',
        },
        h1: {
            fontSize: '52px',
            fontWeight: '600'
        },
        h2: {
            fontSize: '22px',
            fontWeight: '400',
            marginTop: '20px',
            maxWidth: '720px',
        },
        h3: {
            fontSize: '38px',
            fontWeight: '600',
            marginBottom: '10px',
            color: '#2cbc63',
        }
    }
    return (
        <Box sx={style.root}>
            <Grid container spacing={BreakpointsHelper.isMobile() ? 2 : 4}>
                <>
                    {
                        BreakpointsHelper.isMobile() ? (
                            <>
                                <Grid item md={6} xs={12}>
                                    <img src={AboutImage} alt="slider image" style={style.image}/>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Typography variant="h1" sx={style.h3}>
                                        Najwyższy standard
                                    </Typography>
                                    <Typography variant="h2" sx={style.h1}>
                                        Biuro Rachunkowe<br/>Complex Finance<Dot/>
                                    </Typography>
                                    <Typography sx={style.h2}>
                                        Kochamy cyfry i bilanse, ale największą satysfakcję daje nam wspieranie
                                        <b> CEIDG</b>, <b>spółek i firm</b> w ich dynamicznym rozwoju. Z nami księgowość to
                                        nie
                                        rutyna, a <b>droga do sukcesu</b> Twojego biznesu. Zapomnij o papierkowej
                                        robocie i skomplikowanych formalnościach. Wybierz biuro rachunkowe,
                                        które rozumie Twoje potrzeby. Wybierz <b>Complex Finance</b>.
                                    </Typography>
                                    <Box sx={{display: 'flex', marginTop: '20px'}}>
                                        <Button
                                            sx={{
                                                marginRight: '20px',
                                                color: 'white',
                                                borderRadius: '20px !important',
                                                height: '46px'
                                            }}
                                            onClick={() => window.open('tel:+48723421062', '_self')}
                                            variant="contained"
                                        >
                                            Darmowa konsultacja
                                        </Button>
                                    </Box>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item md={6} xs={12}>
                                    <Typography variant="h1" sx={style.h3}>
                                        Najwyższy standard
                                    </Typography>
                                    <Typography variant="h2" sx={style.h1}>
                                        Biuro Rachunkowe<br/>Complex Finance<Dot/>
                                    </Typography>
                                    <Typography sx={style.h2}>
                                        Kochamy cyfry i bilanse, ale największą satysfakcje daje nam wspieranie
                                        <b>CEIDG</b>, <b>spółek i firm</b> w ich dynamicznym rozwoju. Z nami księgowość to
                                        nie
                                        rutyna, a <b>droga do sukcesu</b> Twojego biznesu. Zapomnij o papierkowej
                                        robocie i skomplikowanych formalnościach. Wybierz biuro rachunkowe,
                                        które rozumie Twoje potrzeby. Wybierz <b>Complex Finance</b>.
                                    </Typography>
                                    <Box sx={{display: 'flex', marginTop: '20px'}}>
                                        <Button
                                            sx={{
                                                marginRight: '20px',
                                                color: 'white',
                                                borderRadius: '20px !important',
                                                height: '46px'
                                            }}
                                            onClick={() => window.open('tel:+48723421062', '_self')}
                                            variant="contained"
                                        >
                                            Darmowa konsultacja
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <img src={AboutImage} alt="slider image" style={style.image}/>
                                </Grid>
                            </>
                        )
                    }
                </>
            </Grid>
        </Box>
    )
}

export default Slider
