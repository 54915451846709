import {Typography} from '@mui/material'
import Seo from '../../components/molecules/Seo/Seo'
import Footer from '../../components/molecules/Footer/Footer'
import SizeWindow from '../../components/atoms/SizeWindow/SizeWindow'
import {useTheme} from '@mui/styles'

const RegulationPage = () => {
  const theme = useTheme()
  const { company, phone, email, website, address } = theme.config.seo
  return (
    <>
      <Seo data={{ title: '', page: 'Regulamin serwisu' }}/>
      <SizeWindow minHeight={'100px'}>
        <Typography sx={{marginTop: '100px', fontSize: '24px'}}>
          REGULAMIN SERWISU INTERNETOWEGO {website?.toUpperCase()}
          <br/><br/>
          I. POSTANOWIENIA OGÓLNE<br/><br/>
          1. Regulamin określa warunki świadczenia usług za pomocą Serwisu oraz
          zasady korzystania z Serwisu.<br/><br/>
          2. Serwis udostępniany jest przez Administratora.<br/><br/>
          3. Przed rozpoczęciem korzystania z Serwisu, Użytkownik zobowiązany jest
          zapoznać się z treścią Regulaminu.<br/><br/>
          4. Korzystanie z Serwisu jest jednoznaczne ze złożeniem przez Użytkownika
          oświadczenia, że:<br/><br/>
          a. zapoznał się z Regulaminem i akceptuje wszystkie jego postanowienia<br/><br/>
          i zobowiązuje się do nich
          b. przestrzegania ich<br/><br/>
          c. dobrowolnie przystąpił do korzystania Serwisu i usług świadczonych
          za jego pomocą<br/><br/>
          d. wyraża zgodę na przetwarzanie swoich danych osobowych przez
          Administratora, dla celów należytego wykonania umowy o
          świadczeniu usług drogą elektroniczną oraz innych usług określonych
          w Regulaminie<br/><br/>
          e. wyraża zgodę na otrzymywanie informacji systemowych, wiadomości
          od Administratora oraz informacji o utrudnieniach, zmianach czy
          przerwach technicznych w działaniu Serwisu<br/><br/>
          5. W przypadku niezaakceptowania Regulaminu Użytkownik powinien
          powstrzymać się od korzystania z Serwisu.<br/><br/>
          II. DEFINICJE<br/><br/>
          Na potrzeby Regulaminu, użyte w nim terminy oznaczają:<br/><br/>
          1. Administrator – firma {company}, {address}, email: {email}, strona internetowa: {website}<br/><br/>
          2. Konsument – osoba fizyczna będąca stroną Umowy lub wnioskująca o jej
          zawarcie, w celach niezwiązanych bezpośrednio z jej działalnością
          gospodarczą lub zawodową<br/><br/>
          3. Konto – dostępne po zarejestrowaniu się i zalogowaniu przez podanie loginu
          i hasła, przypisane do Użytkownika miejsce w Serwisie, za pomocą którego
          korzysta on z niektórych usług Serwisu<br/><br/>
          4. Regulamin – niniejszy Regulamin serwisu internetowego {website}<br/><br/>
          5. Serwis – platforma internetowa {website}, składająca się ze stron,
          zakładek i innych narzędzi internetowych
          udostępnianych za pośrednictwem sieci Internet, umożliwiająca korzystanie
          z usług, o których mowa w Regulaminie.<br/><br/>
          6. Użytkownik – korzystająca w jakikolwiek sposób z Serwisu osoba fizyczna
          (Konsument), osoba prawna lub jednostka organizacyjna niemająca
          osobowości prawnej, utworzona zgodnie z odrębnymi przepisami.<br/><br/>
          7. Strony internetowe serwisu – oznacza strony internetowe, pod którymi
          usługodawca prowadzi serwis internetowy, działające w domenie {website}.<br/><br/>
          8. Usługa – oznacza usługę oferowaną przez Sprzedawcę za pośrednictwem
          strony internetowej serwisu, w tym usługę świadczoną drogą elektroniczną.<br/><br/>
          III. WŁASNOŚĆ INTELEKTUALNA
          <br/><br/>
          1. Serwis wraz z zawartymi w nim utworami, znakami towarowymi, bazami
          danych podlega ochronie przewidzianej w obowiązujących przepisach
          prawa, w szczególności w ustawie z dnia 4 lutego 1994 r. o prawie autorskim
          i prawach pokrewnych (t. j. Dz. U. z 2016 r., poz. 666 ze zm.), ustawie z dnia
          30 czerwca 2000 r. Prawo własności przemysłowej (t. j. Dz. U. z 2013 r., poz.
          1410 ze zm.), ustawie z dnia 27 lipca 2001 r. o ochronie baz danych (Dz. U.
          nr 128, poz. 1402 ze zm.) oraz ustawie z dnia 16 kwietnia 1993 r. o
          zwalczaniu nieuczciwej konkurencji (t. j. Dz. U. z 2003 r. nr 153, poz. 1503 ze
          zm.).<br/><br/>
          2. Prawa autorskie do Serwisu oraz zawartych w nim utworów, w rozumieniu
          ustawy z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych (t.
          j. Dz. U. z 2016 r., poz. 666 ze zm.), prawa do znaków towarowych, i baz
          danych przysługują Administratorowi.<br/><br/>
          3. Poprzez korzystanie z utworów, znaków towarowych albo baz danych
          udostępnionych w Serwisie, Użytkownik nie nabywa do nich jakichkolwiek
          praw ani też nie jest mu udzielana jakakolwiek licencja.<br/><br/>
          4. Bez uprzedniej zgody Administratora Użytkownik może korzystać z Serwisu
          oraz utworów i baz danych w nim zamieszczonych wyłącznie w ramach
          dozwolonego użytku przewidzianego w ustawie o prawie autorskim i
          prawach pokrewnych oraz w ustawie o ochronie baz danych. W
          szczególności niedopuszczalne jest:<br/><br/>
          a. zwielokrotnianie, modyfikowanie, blokowanie, publiczne odtworzenie
          i publiczne udostępnianie w sieci Internet Serwisu lub jego części lub
          utworów zamieszczonych w Serwisie lub ich części, za wyjątkiem
          przypadków wskazanych w obowiązujących w tym zakresie
          przepisach odrębnych<br/><br/>
          b. pobieranie zawartości baz danych i wtórne ich wykorzystywanie w
          całości lub w istotnej, co do jakości lub ilości, części.
          <br/><br/>
          IV. REJESTRACJA UŻYTKOWNIKA
          <br/><br/>
          1. W celu uzyskania dostępu do wszystkich usług oferowanych Użytkownikowi
          za pomocą Serwisu, Użytkownik może założyć w Serwisie Konto. W tym celu
          powinien się zarejestrować, postępując zgodnie z instrukcją zawartą w
          panelu rejestracyjnym Serwisu.<br/><br/>
          2. W celu założenia Konta konieczne jest podanie następujących danych:
          a. Imię<br/><br/>
          b. Nazwisko<br/><br/>
          c. Email<br/><br/>
          d. Hasło do konta<br/><br/>
          e. Numer telefonu<br/><br/>
          3. Użytkownik na etapie wypełniania formularza rejestracyjnego ma możliwość
          zapoznania się z warunkami Regulaminu, które mogą zostać przez
          Użytkownika zapisane na trwałym nośniku lub wydrukowane.<br/><br/>
          4. Po założeniu Konta, Administrator będzie sam odblokowywał konto, by była
          możliwość do jego zalogowania przez osobę rejestrującą konto. W ten
          sposób zablokuje się dostęp do osób niepowołanych.<br/><br/>
          5. Po aktywacji Konta, Użytkownik może zalogować się na Konto za pomocą
          podanych w procesie rejestracji email oraz hasła.<br/><br/>
          6. Konto udostępnia następujące funkcjonalności:<br/><br/>
          a. Podgląd do statystyk swojej strony firmowej<br/><br/>
          b. Dostęp do swojej bazy danych<br/><br/>
          c. Dostęp do danych osobowych oraz hasła oraz możliwość ich zamiany<br/><br/>
          d. Nie ma możliwości zmiany uprawnień konta samodzielnie oraz nie
          możliwości wcale zmiany email w danym koncie<br/><br/>
          7. Użytkownik jest odpowiedzialny za ochronę email i hasła używanego do
          uzyskania dostępu do Konta w Serwisie. Użytkownik zobowiązuje się nie
          ujawniać hasła osobom trzecim. Administrator nie ponosi
          odpowiedzialności za jakiekolwiek straty lub szkody wynikające z
          niezastosowania się do powyższych wymagań.<br/><br/>
          8. Użytkownik poinformuje Administratora, bez zbędnej zwłoki, o każdym
          naruszeniu bezpieczeństwa lub nieautoryzowanego użycia konta
          Użytkownika.<br/><br/>
          9. Dokonując rejestracji Użytkownik wyraża zgodę na przetwarzanie przez
          Administratora swoich danych osobowych, w rozumieniu ustawy z dnia 29
          sierpnia 1997 r. o ochronie danych osobowych (t. j. Dz. U. z 2016 r. poz. 922
          ze zm.), w celu realizacji zadań Serwisu, tj. m. in. umożliwienie założenia
          Konta w Serwisie, złożenia rezerwacji na usługi świadczone przez
          Administratora oraz realizację i obsługę tych usług.
          <br/><br/>
          V. PRAWA I OBOWIĄZKI UŻYTKOWNIKA
          <br/><br/>
          1. Użytkownik może mieć tylko jedno Konto i nie może udostępniać Konta
          osobom trzecim ani korzystać z Kont należących do osób trzecich.<br/><br/>
          2. Użytkownik zobowiązuje się powstrzymać od działań mogących utrudniać
          lub zakłócać działanie Serwisu, a w szczególności działań polegających na
          niszczeniu, zmianie, usuwaniu, utrudnianiu dostępu do Kont innych
          Użytkowników.<br/><br/>
          3. W przypadku stwierdzenia przez Administratora naruszenia postanowień, o
          których mowa w ust. 1-2,<br/><br/>
          4. Administratorowi przysługuje prawo zablokowania Konta bądź rozwiązania
          umowy o świadczeniu usług droga elektroniczną.<br/><br/>
          5. Użytkownik zobowiązuje się do korzystania z Serwisu zgodnie z
          obowiązującymi przepisami prawa, powszechnie przyjętymi normami
          moralnymi, społecznymi, obyczajowymi i kulturalnymi, postanowieniami
          Regulaminu oraz zasadami współżycia społecznego, w tym również z etyką.<br/><br/>
          6. Użytkownik ma prawo żądać od Administratora usunięcia Konta, w tym celu
          powinien przesłać Administratorowi odpowiednie oświadczenie drogą
          elektroniczną na adres: {email}. Usunięcie Konta wiąże się z
          usunięciem danych Użytkownika z bazy danych Serwisu. Jednak historia
          użytkownika w systemie nadal pozostaje, o tym co robił w czasie przeszłym
          w systemie.<br/><br/>
          7. Użytkownik może kontaktować się z Administratorem poprzez:<br/><br/>
          a. poczta elektroniczną: {email}<br/><br/>
          b. za pośrednictwem formularza kontaktowego
          na stronie {website}<br/><br/>
          c. za pośrednictwem chatu znajdującego się na wyżej wskazanej stronie
          internetowej<br/><br/>
          d. telefonicznie: {phone} – koszt połączenia według stawek
          operatora realizującego połączenie<br/><br/>
          8. Sposób i termin spełnienia przez Administratora świadczenia zamówionego
          przez Użytkownika podawane są każdorazowo podczas procedury składania
          przez Użytkownika zamówienia.
          <br/><br/>
          VI.OGRANICZENIA W KORZYSTANIU Z SERWISU
          <br/><br/>
          1. Zakazane jest wykorzystywanie Serwisu w sposób sprzeczny lub niewłaściwy
          z jego celem. Zakazane jest przesyłanie i publikowanie za pomocą Serwisu
          treści zabronionych przez powszechnie obowiązujące prawo, w tym umowy
          międzynarodowe, prawo unijne i prawo Rzeczypospolitej Polskiej. Zabrania
          się w szczególności:<br/><br/>
          a. korzystania z Serwisu dla jakichkolwiek celów niezgodnych z prawem
          lub do promowania nielegalnych działań<br/><br/>
          b. zamieszczania w Serwisie lub z jego pomocą treści z naruszeniem
          powszechnie obowiązujących przepisów prawa, w tym prawa
          własności intelektualnej i prawa do prywatności, dóbr osobistych
          osób trzecich, itp., a w szczególności treści:<br/><br/>
          i. naruszających prawa osobiste i/lub majątkowe lub dobra
          osobiste osób trzecich,<br/><br/>
          ii. powszechnie uznawanych za wulgarne, obraźliwe,<br/><br/>
          iii. obrazujących lub propagujących przemoc, faszyzm, rasizm,
          nienawiść lub jakąkolwiek dyskryminację (np.<br/><br/>
          iv. rasową, kulturową, etniczną, religijną, filozoficzną, fizjologiczną,
          majątkową),<br/><br/>
          v. o charakterze erotycznym, pornograficznym lub
          obscenicznym,<br/><br/>
          vi. upowszechniających techniki łamania zabezpieczeń danych
          (mechanicznych i/lub elektronicznych), dot.<br/><br/>
          vii. tworzenia i rozprzestrzeniania wirusów i oprogramowania o
          podobnym charakterze oraz treści<br/><br/>
          viii. opisujących, promujących lub dostarczających instrukcji do
          działań niezgodnych z prawem lub działań<br/><br/>
          ix. choćby potencjalnie niebezpiecznych,<br/><br/>
          c. podszywania się pod innych Użytkowników<br/><br/>
          d. rozsyłania za pomocą Serwisu niezamówionych komunikatów, reklam
          lub spamu.<br/><br/>
          2. Użytkownikowi zabrania się działalności (zachowania) polegającej na:<br/><br/>
          a. łamaniu ogólnie przyjętych zasad etyki<br/><br/>
          b. używaniu programów generujących automatyczne zapytania do
          serwerów Administratora<br/><br/>
          c. kopiowaniu części lub całości Serwisu, w tym kodu źródłowego lub
          wykorzystywaniu go w celach zarobkowych<br/><br/>
          d. rozsyłaniu spamu lub niezamówionej informacji handlowej, reklam
          towarów, usług oraz uprawiania działalności komercyjnej, reklamowej
          lub promocyjnej w Serwisie<br/><br/>
          e. promowaniu innych stron/serwisów/portali internetowych.<br/><br/>
          3. Użytkownik zobowiązuje się powstrzymywać od naruszania praw autorskich
          do Serwisu przysługujących Administratorowi, a także praw autorskich osób
          trzecich.<br/><br/>
          4. Użytkownik ponosi pełną odpowiedzialność za naruszenie Regulaminu,
          praw lub dóbr osób trzecich
          <br/><br/>
          VII. ODPOWIEDZIALNOŚĆ ADMINISTRATORA
          <br/><br/>
          1. Administrator podejmuje wszelkie starania, aby zapewnić prawidłowe
          funkcjonowanie Serwisu pod względem technicznym.<br/><br/>
          2. Administrator nie ponosi odpowiedzialności:<br/><br/>
          a. Za brak możliwości korzystania przez Użytkownika ze świadczonych
          usług w następstwie niespełnienia przez Użytkownika wymagań
          technicznych<br/><br/>
          b. Za jakiekolwiek szkody i straty Użytkownika powstałe bezpośrednio
          lub pośrednio (łącznie ze szkodami z tytułu utraty zysków z
          prowadzenia przedsiębiorstwa, przerw w działalności
          przedsiębiorstwa lub utraty informacji gospodarczej oraz innymi
          szkodami o charakterze majątkowym), w związku z korzystaniem lub
          niemożliwością korzystania przez Użytkownika z Serwisu albo
          błędnego działania Serwisu<br/><br/>
          c. w związku z niewłaściwym korzystaniem przez Użytkownika z Serwisu,
          w szczególności w sposób sprzeczny z powszechnie obowiązującym
          prawem i Regulaminem oraz nieprawidłowym funkcjonowaniem
          sprzętu komputerowego, oprogramowania komputerowego, czy też
          systemu łączności, za pomocą których Użytkownik łączy się z
          Serwisem<br/><br/>
          d. za sposób, w jaki Użytkownik korzysta z Serwisu<br/><br/>
          e. za informacje pobrane z Internetu, w szczególności z Serwisu, ani za
          skutki ich wykorzystania przez Użytkownika oraz za ich przydatność
          dla Użytkownika<br/><br/>
          f. za szkody poniesione przez Użytkownika spowodowane
          zagrożeniami występującymi w publicznej sieci Internet, w
          szczególności włamaniami do systemu Użytkownika, przejęciami
          haseł przez osoby trzecie, zainfekowaniem systemu Użytkownika
          wirusami.<br/><br/>
          3. Administrator nie gwarantuje Użytkownikowi ciągłej (nieprzerwanej,
          bezawaryjnej) pracy Serwisu, jeżeli przerwa w działaniu wynikła:<br/><br/>
          a. z przyczyn niezawinionych przez Administratora, w szczególności
          leżących po stronie Użytkownika, osób trzecich, dostawcy usług,
          operatora odpowiedzialnego za transmisję danych<br/><br/>
          b. z konieczności przeprowadzenia przez Administratora niezbędnych
          prac konserwacyjnych lub serwisowych<br/><br/>
          c. działania siły wyższej<br/><br/>
          4. Serwis może zawierać linki do stron internetowych osób trzecich (np.
          reklamodawców), które nie są własnością i nie są kontrolowane przez
          Administratora. Administrator nie ponosi jakiejkolwiek odpowiedzialności za
          którąkolwiek z tych stron oraz informacji, materiałów, produktów lub usług
          w nich zawartych.<br/><br/>
          5. Administrator zastrzega sobie prawo do zaprzestania świadczenia usług
          poprzez zablokowanie Konta Użytkownika, którego działania uzna za
          szkodliwe dla Serwisu, lub który łamie którekolwiek z postanowień
          Regulaminu. Ponowienie świadczenia usług poprzez odblokowanie konta
          Użytkownikowi pozostawione jest do swobodnej decyzji Administratora.<br/><br/>
          6. Nieodblokowanie Konta Użytkownika przez okres trzech miesięcy licząc od
          dnia zablokowania Konta, skutkuje skasowaniem Konta Użytkownika i
          usunięcia danych osobowych dotyczących Użytkownika.<br/><br/>
          <br/><br/>
          VIII. OPŁATY ZA KORZYSTANIE Z SERWISU
          <br/><br/>
          1. Korzystanie z Serwisu przez Użytkownika, w tym utworzenie i korzystanie z
          Konta jest bezpłatne, z tym zastrzeżeniem, że dostęp do Serwisu wymaga
          połączenia z Internetem, za co Użytkownik może być obciążony przez
          swojego operatora internetowego kosztami wynikającymi z łączącej ich
          umowy.<br/><br/>
          2. Do części korzystania z serwisu jest ustalony dostęp płatny za
          pośrednictwem portalu istnieje możliwość zamówienia tego dostępu.<br/><br/>
          3. Sposób i termin zapłaty za świadczone za pośrednictwem Serwisu usługi,
          podawane są każdorazowo podczas procedury składania przez Użytkownika
          zamówienia.
          <br/><br/>
          IX. DANE UŻYTKOWNIKA I ICH OCHRONA, POLITYKA PRYWATNOŚCI
          <br/><br/>
          1. Administrator przywiązuje szczególną wagę do poszanowania prywatności
          Użytkownika korzystającego z Serwisu.<br/><br/>
          2. W przypadku korzystania z niektórych usług świadczonych za pomocą
          Serwisu Użytkownik będący osobą fizyczną może podać swoje dane
          osobowe. Podanie danych osobowych jest dobrowolne, jednakże może być
          niezbędne do prawidłowego świadczenia niektórych usług za pomocą
          Serwisu.<br/><br/>
          3. Dane osobowe mogą zostać zweryfikowane przez Administratora. W tym
          celu Administrator może wymagać od Użytkownika złożenia innych
          oświadczeń niż przewidziane Regulaminem.<br/><br/>
          4. Administrator świadczy usługi z pomocą Serwisu, zgodnie z ustawą z dnia 29
          sierpnia 1997 r. o ochronie danych osobowych (t. j. Dz. U. z 2016 r., poz. 922
          ze zm.) oraz aktami wykonawczymi wydanymi na jej podstawie.<br/><br/>
          5. Administratorem danych osobowych jest Administrator.<br/><br/>
          6. Administrator przetwarza dane osobowe na podstawie art. 23 ust. 1 pkt 1
          ustawy o ochronie danych osobowych.<br/><br/>
          7. Administrator będzie przetwarzać dane osobowe wyłącznie w celu
          wykonania danej usługi świadczonej za pomocą Serwisu i w zakresie
          niezbędnym do jej wykonania.<br/><br/>
          8. Administrator przetwarzając dane osobowe, dokłada szczególnej
          staranności w celu ochrony interesów osób, których dane te dotyczą, a w
          szczególności zapewnia, aby dane te były:<br/><br/>
          a. przetwarzane zgodnie z powszechnie obowiązującym prawem<br/><br/>
          b. zbierane dla oznaczonych Regulaminie celów i niepoddawane
          dalszemu przetwarzaniu niezgodnemu z tymi celami<br/><br/>
          c. merytorycznie poprawne i adekwatne w stosunku do celów, w jakich
          są przetwarzane<br/><br/>
          d. przechowywane w postaci umożliwiającej identyfikację osób, których
          dane te dotyczą, nie dłużej niż jest to niezbędne do osiągnięcia celu
          przetwarzania.<br/><br/>
          e. Do przetwarzania danych osobowych są dopuszczone wyłącznie
          osoby posiadające upoważnienie nadane przez Administratora.<br/><br/>
          9. Administrator zabezpiecza dane osobowe przed ich udostępnieniem
          osobom nieupoważnionym, zabraniem przez osobę nieuprawnioną,
          przetwarzaniem z naruszeniem ustawy o ochronie danych osobowych oraz
          zmianą, utratą, uszkodzeniem lub zniszczeniem, w sposób określony
          przepisami art. 36 - 39a ustawy o ochronie danych osobowych oraz dokłada
          należytej staranności przy przetwarzaniu danych osobowych poprzez
          stosowanie środków technicznych i organizacyjnych, o których mowa w
          rozporządzeniu Ministra Spraw Wewnętrznych i Administracji z dnia 29
          kwietnia 2004 r. w sprawie dokumentacji przetwarzania danych osobowych
          oraz warunków technicznych i organizacyjnych, jakim powinny odpowiadać
          urządzenia i systemy informatyczne służące do przetwarzania danych
          osobowych (Dz. U. nr 100, poz. 1024).<br/><br/>
          10.Wykorzystywany przez Administratora w procesie przetwarzania danych
          osobowych, system informatyczny, spełnia wymagania określone w
          rozporządzeniu, o którym mowa w ust. 10.<br/><br/>
          11.Dane osobowe przetwarzane są w siedzibie spółki, zlokalizowanej w {address}<br/><br/>
          12.Zakres danych osobowych przetwarzanych przez Administratora w związku
          ze świadczeniem usług objętych Regulaminem, obejmuje:<br/><br/>
          a. imię i nazwisko<br/><br/>
          b. nr telefonu<br/><br/>
          c. adres poczty elektronicznej<br/><br/>
          d. nazwa firmy<br/><br/>
          e. uprawnienia w aplikacji<br/><br/>
          13.Administrator może powierzyć przetwarzanie danych osobowych osobie
          trzeciej.<br/><br/>
          14.Administrator nie będzie udostępniać zgromadzonych danych osobowych<br/><br/>
          osobom trzecim, chyba że nastąpi to wskutek wyraźnej zgody.<br/><br/>
          15.Administrator zapewnia realizację uprawnień wynikających z ustawy z dnia
          29 sierpnia 1997 r. o ochronie danych osobowych (t. j. Dz. U. z 2016 r., poz.
          922 ze zm.), a w szczególności umożliwia:<br/><br/>
          a. wgląd do własnych danych osobowych i ich poprawianie<br/><br/>
          b. wniesienie, w przypadkach wymienionych w ustawie, pisemnego
          umotywowanego żądania zaprzestania<br/><br/>
          c. przetwarzania własnych danych osobowych.<br/><br/>
          16. W celu realizacji uprawnień, o których mowa w ust. 16, należy zwrócić się na
          piśmie do Administratora drogą elektroniczną na adres {email} z dopiskiem „Dane osobowe”.<br/><br/>
          17. Szczegółowe informacje na temat polityki ochrony prywatności stosowanej
          przez Administratora zawiera dokument Polityka Prywatności, udostępniony
          przez Administratora pod adresem {website}.
          <br/><br/>
          X. REKLAMACJE
          <br/><br/>
          1. Użytkownikowi Serwisu przysługuje prawo do złożenia reklamacji w
          sprawach związanych z technicznym funkcjonowaniem Serwisu.<br/><br/>
          2. Reklamacje można składać drogą elektroniczną na adres: {email}.<br/><br/>
          3. Administrator rozpatrzy reklamację w terminie 14 dni od dnia jej otrzymania,
          z zastrzeżeniem ust. 4.<br/><br/>
          4. Administrator zastrzega sobie prawo do wydłużenia terminu podanego w
          ust. 3, o nie więcej niż 20 dni w przypadku, gdy rozpoznanie reklamacji
          wymaga nietypowych, szczególnych działań i ustaleń lub napotka
          przeszkody niezależne i niezawinione od Administratora (dodatkowe
          wyjaśnienia, awaria sprzętu, siła wyższa, itp.). Administrator zastrzega
          ponadto, że rzetelne rozpatrzenie reklamacji może wymagać uzyskania od
          Użytkownika dodatkowych wyjaśnień.
          <br/><br/>
          XI. DOSTĘP DO SERWISU I WYMAGANIA TECHNICZNE
          <br/><br/>
          1. Administrator świadczy usługi poprzez Serwis za pomocą sieci Internet.
          Użytkownik może korzystać z poszczególnych funkcjonalności Serwisu za
          pomocą dowolnego komputera lub urządzenia mobilnego (tablet, smartfon,
          itp.), w dowolny sposób połączonego z Internetem.<br/><br/>
          2. Dla prawidłowego działania Serwisu wymagane jest posiadanie sprzętu
          komputerowego z aktualnym oprogramowaniem, które zapewniają dostęp
          do sieci Internet oraz posiadanie zaktualizowanej przeglądarki internetowej
          zapewniającej prawidłowe wyświetlanie stron internetowych.<br/><br/>
          3. W sytuacji gdy Użytkownik napotkana problemy techniczne z dostępem do
          Serwisu lub jej poszczególnymi funkcjonalnościami może przesłać
          zgłoszenie do Administratora na adres: {email}.
          <br/><br/>
          XII. POSTANOWIENIA KOŃCOWE
          <br/><br/>
          1. Podstawowym zagrożeniem każdego użytkownika Internetu, w tym osób
          korzystających z usług świadczonych drogą elektroniczną, jest możliwość
          zainfekowania urządzenia elektronicznego (systemu informatycznego) przez
          różnego rodzaju oprogramowanie tworzone głównie w celu wyrządzania
          szkód (wirusy, robaki czy konie trojańskie. W celu uniknięcia zagrożeń z tym
          związanych, ważne jest, by Użytkownik zaopatrzył swój komputer
          (urządzenie), który wykorzystuje podłączając się do Internetu, w program
          antywirusowy i stale go aktualizował,<br/><br/>
          2. instalując jego najnowsze wersje, niezwłocznie po pojawieniu się ich na
          rynku. Administrator informuje również, że szczególne zagrożenia związane
          z korzystaniem z usługi świadczonej drogą elektroniczną, w tym i opisywanej
          w Regulaminie, wiążą się z działalnością hackerów, zmierzających do
          włamania się zarówno do systemu Administratora (np. ataki na jego witryny),
          jak i Użytkownika. Użytkownik przyjmuje zatem do wiadomości, że mimo
          stosowania przez Administratora rozmaitych, nowoczesnych technologii, nie
          istnieje perfekcyjne zabezpieczenie chroniące przed opisanymi wyżej
          niepożądanymi działaniami.<br/><br/>
          3. Aktualna treść Regulaminu dostępna jest na stronie internetowej Serwisu
          oraz w siedzibie Administratora.<br/><br/>
          4. Administrator zastrzega sobie prawo do zmiany Regulaminu. Zmiana
          Regulaminu może nastąpić w szczególności w wypadku zmian
          technologicznych lub organizacyjnych w działaniu Serwisu.<br/><br/>
          5. O zmianach Regulaminu Administrator zawiadamia Użytkownika
          udostępniając treść nowego, ujednoliconego Regulaminu na stronie
          internetowej Serwisu.<br/><br/>
          6. Zmiana Regulaminu wchodzi w życie z dniem opublikowania treści nowego
          Regulaminu na stronie internetowej Serwisu.<br/><br/>
          7. Korzystanie przez Użytkownika z Serwisu po wprowadzeniu zmian do
          Regulaminu oznacza ich akceptację.<br/><br/>
          8. W przypadku niezaakceptowania zmian w Regulaminie Użytkownik powinien
          powstrzymać się od korzystania z Serwisu.<br/><br/>
          9. Administratorowi przysługuje prawo do zaprzestania świadczenia usług za
          pomocą Serwisu.<br/><br/>
          10.W kwestiach nieuregulowanych postanowieniami Regulaminu zastosowanie
          mają obowiązujące przepisy prawa polskiego, a wszelkie spory rozstrzygane
          będą przez właściwe polskie sądy powszechne.<br/><br/>
        </Typography>
      </SizeWindow>
      <Footer/>
    </>
  )
}

export default RegulationPage
