import {Typography} from '@mui/material'
import Seo from '../../components/molecules/Seo/Seo'
import Footer from '../../components/molecules/Footer/Footer'
import SizeWindow from '../../components/atoms/SizeWindow/SizeWindow'
import {useTheme} from '@mui/styles'

const PolicyPage = () => {
    const theme = useTheme()
    const { company, website } = theme.config.seo
  return (
    <>
        <Seo data={{ title: '', page: 'Polityka prywatności' }}/>
        <SizeWindow minHeight={'100px'}>
            <Typography sx={{ marginTop: '100px', fontSize: '24px' }}>
                <b>POLITYKA PRYWATNOŚCI SERWISU INTERNETOWEGO {website.toUpperCase()}</b>
                <br/><br/>
                1. Niniejsza Polityka Prywatności określa zasady przetwarzania i ochrony danych
                osobowych przekazanych przez użytkowników w związku z korzystaniem
                przez nich usług związanych z serwisem.
                <br/><br/>
                2. Administratorem danych osobowych zawartych w serwisie jest osoba
                firma {company}.
                <br/><br/>
                3. W trosce o bezpieczeństwo powierzonych nam danych opracowałem
                wewnętrzne procedury i zalecenia, które mają zapobiec w udostępnianiu
                danych osobom nieupoważnionym do tego. Kontroluje ich wykonywania i
                stale sprawdzam zgodność z odpowiednimi aktami prawnymi - ustawą o
                ochronie danych osobowych, ustawą o świadczeniu usług drogą
                elektroniczną, a także wszelkiego rodzaju aktach wykonawczych i aktach
                prawa wspólnotowego.
                <br/><br/>
                4. Dane osobowe przetwarzane są na podstawie zgody wyrażanej przez
                użytkownika oraz w przypadkach, w których przepisy prawa upoważniają
                Administratora do przetwarzania danych osobowych na podstawie przepisów
                prawa lub w celu realizacji zawartej pomiędzy stronami umowy.
                <br/><br/>
                5. Serwis realizuje funkcje pozyskiwania informacji o ich zachowaniach w
                następujący sposób:<br/><br/>
                a. poprzez dobrowolne wprowadzone w formularzach dane<br/><br/>
                b. poprzez gromadzenie plików cookies
                <br/><br/>
                6. Serwis zbiera informacje dobrowolne podane przez użytkownika.
                <br/><br/>
                7. Dane podana w formularzu są przetwarzane w celu wynikającym z funkcji
                konkretnego formularza np:. w celu dokonania procesu obsługi kontaktu
                informacyjnego.
                <br/><br/>
                8. Dane osobowe pozostawione w serwisie nie zostaną sprzedane ani
                udostępnione osobom trzecim, zgodnie z przepisami ustawy o ochronie
                danych osobowych
                <br/><br/>
                9. Do danych zawartych w formularzu przysługuje wgląd, która je
                tam umieściła. Osoba ta ma również prawo do modyfikacji i zaprzestania
                przetwarzania swoich danych w dowolnym momencie.
                <br/><br/>
                10.Zastrzegam sobie prawo do zmiany polityki prywatności serwisu, na które
                może wpłynąć rozwój technologii internetowej, ewentualnie zmiany prawa w
                zakresie ochrony danych osobowych oraz rozwój serwisu internetowego. O
                wszelkich zmianach będziemy informować w sposób widocznych i zrozumiały
                dla użytkowników.
                <br/><br/>
                11.W serwisie mogą pojawiać się linki do innych stron internetowych. Takie
                strony internetowe działają niezależnie od serwisu i nie są w żaden sposób
                nadzorowane przez {website}. Strony te mogą posiadać własne
                polityki prywatności dotyczące prywatności oraz regulaminy, z którymi
                zalecamy się zapoznać. W razie wątpliwości do któregokolwiek z zapisów
                niniejszej polityki prywatności jesteśmy do dyspozycji - nasze dane można
                znaleźć na stronie internetowej {website}
                <br/><br/>
            </Typography>
        </SizeWindow>
        <Footer />
    </>
  )
}

export default PolicyPage
