import Typography from '@mui/material/Typography'
import Footer from '../../components/molecules/Footer/Footer'
import Page from '../../components/molecules/Page/Page'
import Seo from '../../components/molecules/Seo/Seo'
import DueImage from '../../assets/images/old/1.png'
import DueImage2 from '../../assets/images/old/2.png'
import ContactBanner from '../../components/molecules/ContactBanner/ContactBanner'
import Button from '@mui/material/Button'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
const DueDiligencePage = () => {
  const style = {
    desc: {
      '> *': {
        fontSize: '22px',
        fontWeight: '600',
      },
      fontSize: '22px',
    },
    titleBox: {
      fontSize: '22px',
      textAlign: 'left',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginBottom: '10px',
    },
    img: {
      fill: '#2cbc63',
      width: '40px',
      marginRight: '15px',
    }
  }
  return (
    <>
      <Seo data={{ 'seo: title': 'Complex Finance - Due diligence i start-up' }}/>
      <Page
        image={DueImage}
        title={'Due diligence i start-up'}
        small={'Planujesz działalność w Polsce? Jesteśmy tu, by pomóc'}
        description={(
          <>
            <Typography sx={style.desc}>
              Stawiasz pierwsze kroki w świecie biznesu lub planujesz ekspansję na
              rynek polski? Complex Finance to Twój klucz do sukcesu! Specjalizujemy
              się w świadczeniu kompleksowego wsparcia dla nowych przedsiębiorstw i
              firm pragnących rozwinąć swoją działalność w Polsce. Oferujemy nie tylko
              profesjonalne doradztwo, ale praktyczne wsparcie w realizacji Twoich
              planów biznesowych. Jako partner, stojąc u Twojego boku na każdym etapie
              rozwoju, zapewniamy, że Twój biznes będzie prosperował w sposób
              zorganizowany i dynamiczny.
            </Typography>
              <Button
                  onClick={() => window.open('/contact', '_self')}
                  variant="contained"
                  sx={{ marginRight: '20px', color: 'white', borderRadius: '20px !important', height: '46px', marginTop: '30px', width: BreakpointsHelper.isMobile() ? '100%' : 'auto' }}
              >
                  Zadzwoń do nas
              </Button>
          </>
        )}
      />
      <Page
        revert
        image={DueImage2}
        description={(
          <>
            <Typography sx={style.titleBox}><b>Pełen przegląd Due Diligence</b></Typography>
            <Typography sx={style.desc}>
              Przygotowujesz się do zakupu firmy? Nasz szczegółowy przegląd due
              diligence to fundament, na którym możesz bezpiecznie oprzeć swoje
              decyzje inwestycyjne. Dokładnie analizujemy finansowe i podatkowe
              aspekty potencjalnego przejęcia, oferując Ci jasny obraz kondycji
              finansowej targetu. Skoncentrowani na wykrywaniu wszelkiego ryzyka oraz
              szans, jakie niesie planowana transakcja, dajemy Ci narzędzia do
              podejmowania świadomych decyzji, które będą wspierać długoterminowy
              sukces Twojego biznesu.
            </Typography>
            <Typography sx={style.titleBox}><b>Optymalizacja biznesowa</b></Typography>
            <Typography sx={style.desc}>
              Optymalizacja biznesowa to więcej niż tylko redukcja kosztów – to
              strategia na rzecz zwiększenia efektywności operacyjnej. Przeanalizujemy
              Twoją strukturę organizacyjną, procesy, wykorzystanie zasobów ludzkich i
              materialnych oraz technologie, aby zidentyfikować obszary do
              usprawnienia. Nasz cel to pomoc w tworzeniu zwinnej, efektywnej
              organizacji, gotowej na wyzwania współczesnego rynku. Z nami Twoja firma
              zyska solidne fundamenty do wzrostu, innowacji i zwiększenia
              konkurencyjności.
            </Typography>
            <Typography sx={style.desc}>
                Z <b>Complex Finance</b> masz pewność, że każdy aspekt Twojej działalności
              zostanie dokładnie przeanalizowany i zoptymalizowany pod kątem
              osiągnięcia najlepszych możliwych rezultatów. Skontaktuj się z nami już
              dziś, aby dowiedzieć się, jak możemy wspomóc rozwój Twojego biznesu.
            </Typography>
          </>
        )}
      />
      <ContactBanner />
      <Footer/>
    </>
  )
}

export default DueDiligencePage
