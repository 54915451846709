import Footer from '../../components/molecules/Footer/Footer'
import {Grid, Link, useTheme} from '@mui/material'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import kontakt from '../../assets/images/kontakt.jpg'
import Seo from '../../components/molecules/Seo/Seo'
import {useEffect, useState} from 'react'
import {getAllDataFromPath} from '../../firebase'
import Page from '../../components/molecules/Page/Page'
import ContactImage from '../../assets/images/f-70592-czym-jest-tzw-pelna-ksiegowosc.jpg'

const ContactPage = () => {
  const theme = useTheme()
  const { phone, email, address, hours, nip, regon } = theme.config.seo
  const [seoData, setSeoData] = useState(null)
  const style = {
    banner: {
      height: '300px',
      padding: '100px',
      display: 'flex',
      alignItems: 'center',
      width: 'calc(100% - 200px)',
      justifyContent: 'center',
      background: `url(${kontakt})`,
      borderRadius: '50px',
      backgroundPosition: 'center center',
      backgroundSize: '100% auto',
      fitObject: 'cover',
      position: 'relative',
      marginBottom: '100px',
      marginTop: '100px',
    },
    blackBg: {
      position: 'absolute',
      zIndex: 1,
      width: '100%',
      height: '100%',
      borderRadius: '50px',
      background: 'rgba(0,0,0,.3)'
    },
    texts: {
      position: 'absolute',
      zIndex: 2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    title: {
      color: 'white',
      fontWeight: 900,
      fontSize: '65px',
      lineHeight: 1.1
    },
    desc: {
      marginTop: '20px',
      color: 'white',
      fontWeight: 400,
      fontSize: '24px',
      lineHeight: 1.1
    }
  }
  const getSeo = () => {
    getAllDataFromPath('seo').then((res) => {
      if (res) {
        setSeoData(res)
      }
    })
  }
  useEffect(() => {
    getSeo()
  }, [])
  return (
    <>
      <Seo data={seoData?.length > 0 ? seoData?.filter(el => el.id === 'ContactPage')[0]?.data : {}}/>
      <Page
        image={ContactImage}
        title={'Kontakt'}
        small={'Masz pytania, napisz lub zadzwoń'}
        description={(
          <>
            <Grid container spacing={4}>
              <Grid item md={12} xs={12}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h3" sx={{ fontSize: '24px', fontWeight: '600', marginBottom: '10px' }}>Przyjedź</Typography>
                  <Link href={`https://www.google.com/maps/place/${address}/@54.1953834,16.1894642,17z/data=!3m1!4b1!4m5!3m4!1s0x4701cd13bbf74b71:0x5b45fac2e58fc705!8m2!3d54.1953803!4d16.1920445?entry=ttu`}>{address}</Link>
                </Box>
              </Grid>
              <Grid item md={12} xs={12}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h3" sx={{ fontSize: '24px', fontWeight: '600', marginBottom: '10px' }}>Napisz lub Zadzwoń</Typography>
                  <Link sx={{ marginBottom: '10px' }} href={`mailto:${email}`}>{email}</Link>
                  <Link href={`tel:${phone?.replace(' ', '')?.replace(' ', '')?.replace(' ', '')}`}>{phone}</Link>
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h3" sx={{ fontSize: '24px', fontWeight: '600', marginBottom: '10px' }}>Godziny otwarcia</Typography>
                  <Typography variant="p">Pon - Pt:{hours.ponPt}</Typography>
                  <Typography variant="p">Sobota: {hours.sob}</Typography>
                  <Typography variant="p">Niedziela: {hours.nd}</Typography>
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h3" sx={{ fontSize: '24px', fontWeight: '600', marginBottom: '10px' }}>Dane szczegółowe</Typography>
                  <Typography variant="p">NIP: {nip}</Typography>
                  <Typography variant="p">REGON: {regon}</Typography>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      />
      <Footer />
    </>
  )
}

export default ContactPage
