const Dot = ({ char }) => {
  const style = {
    root: {
      color: '#2cbc63',
      marginLeft: '2px',
      fontWeight: '600',
    }
  }
  return (
    <span style={style.root}>{char || '.'}</span>
  )
}

export default Dot
