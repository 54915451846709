import {Box, Button, hexToRgb, Typography} from '@mui/material'
import {useTheme} from '@mui/styles'
import hexToRgba from 'hex-to-rgba'

const PricesBox = ({data}) => {
    const theme = useTheme()
    const name = data?.priceName
    const content = data?.priceData
    const price = data?.price
    const style = {
        root: {
            width: '100%',
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'white',
            borderRadius: '50px',
            maxWidth: '720px',
        },
        name: {
            padding: '20px',
            display: 'flex',
            fontWeight: '600',
            fontSize: '24px',
            width: 'calc(100% - 40px)',
            borderTopLeftRadius: '50px',
            borderTopRightRadius: '50px',
            color: theme.palette.primary.white,
            background: theme.palette.primary.main,
            justifyContent: 'center',
        },
        content: {
            borderRight: `1px solid ${hexToRgba(theme.palette.primary.main, 0.15)}`,
            borderLeft: `1px solid ${hexToRgba(theme.palette.primary.main, 0.15)}`,
            width: '100%',
            textAlign: 'center',
            paddingTop: '20px',
            paddingBottom: '20px',
        },
        boxButton: {
            borderBottomRightRadius: '50px',
            borderBottomLeftRadius: '50px',
            width: '100%',
            color: theme.palette.primary.white,
            border: `1px solid ${hexToRgba(theme.palette.primary.main, 0.15)}`,
            justifyContent: 'center',
            display: 'flex',
        },
        button: {
            marginTop: '20px',
            marginBottom: '20px',
        },
        price: {
            marginLeft: '10px',
            color: theme.palette.primary.white,
        }
    }
    return (
        <Box sx={style.root}>
            <Box sx={style.name}>
                {name}
                <Typography variant="span" sx={style.price}>{price / 100} zł</Typography>
            </Box>
            <Box sx={style.content} dangerouslySetInnerHTML={{ __html: content }} />
            <Box sx={style.boxButton}>
                <Button sx={style.button} variant="contained" onClick={() => window.open('/contact', '_self')}>Skontaktuj się i zamów</Button>
            </Box>
        </Box>
    )
}

export default PricesBox
