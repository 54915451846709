import moment from 'moment'
import DateHelper from './DateHelper'

export default {
  getDiffDate: (date1, date2) => {
    const dates1 = new Date(date1)
    const dates2 = new Date(date2)
    return Math.ceil(Math.abs(dates2.getTime() - dates1.getTime()) / (1000 * 3600 * 24))
  },
  getNameDay: (date) => {
    const days = ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota']
    return days[date?.getDay()]
  },
  getNumberOfDays: (start, end) => {
    const date1 = new Date(start)
    const date2 = new Date(end)
    const oneDay = 1000 * 60 * 60 * 24
    const diffInTime = date2.getTime() - date1.getTime()
    return Math.round(diffInTime / oneDay)
  },
  getDaysToEvent: (el) => {
    const dateSplit = el.data.date.split('-')
    const date = `${dateSplit[2]}/${dateSplit[1]}/${dateSplit[0]}`
    const dateToday = DateHelper.getFormattedDateByFormat(new Date(), 'DD/MM/YYYY')
    return DateHelper.getNumberOfDays(`${dateToday.split('/')[1]}/${dateToday.split('/')[0]}/${dateToday.split('/')[2]}`, `${date.split('/')[1]}/${date.split('/')[0]}/${date.split('/')[2]}`)
  },
  getDateToday: () => new Date(),
  getDaysInMonth: (month, year) => new Date(year, month, 0).getDate(),
  getFormattedDateByFormat: (date, format) => moment(date).format(format),
  getDaysBetweenTwoDates: (dateStart, dateEnd) => {
    const start = dateStart ? moment(dateStart) : moment()
    const end = dateEnd ? moment(dateEnd) : moment()
    return start.diff(end)
  },
  getTranslatedTimeBetweenTwoDates: (dateStart, dateEnd) => {
    const diff = DateHelper.getDaysBetweenTwoDates(dateStart, dateEnd)
    const days = moment.duration(diff, 'milliseconds').asDays()
    const hours = Math.floor(moment.duration(diff, 'milliseconds').asHours())
    const minutes = Math.floor(
      moment.duration(diff - hours * 3600000, 'milliseconds').asMinutes()
    )
    return Math.floor(days) > 1
      ? `${Math.floor(days)} dni`
      : `${hours} godzin ${minutes} minut`
  },
  timeDomainWhenLost: (time) => {
    const timeDate = new Date(
      time.split('.')[0],
      time.split('.')[1],
      time.split('.')[2]
    )
    return DateHelper.getTranslatedTimeBetweenTwoDates(timeDate, DateHelper.getDateToday()) + ', ' + time
  },
}
