import {useEffect, useState} from 'react'
import Tags from '../../components/atoms/Tags/Tags'
import {Avatar, Divider, Grid} from '@mui/material'
import SizeWindow from '../../components/atoms/SizeWindow/SizeWindow'
import {getAllDataFromPath} from '../../firebase'
import Footer from '../../components/molecules/Footer/Footer'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import MagicLoader from '../../components/atoms/MagicLoader/MagicLoader'
import logo from '../../assets/configFiles/favicon.png'
import ArticlePreview from '../../components/molecules/ArticlePreview/ArticlePreview'
import Seo from '../../components/molecules/Seo/Seo'
import {FacebookIcon, FacebookShareButton} from 'react-share'

const PreviewArticlePage = () => {
  const [data, setData] = useState(null)
  const [seoData, setSeoData] = useState({})
  const [dataOptional, setDataOptional] = useState(null)
  const id = window.location.pathname.split('/')[2]
  const style = {
    root: {
      marginTop: '100px',
      marginBottom: '100px'
    },
    box: {
      margin: '0 auto'
    },
    banner: {
      height: '300px',
      padding: '100px',
      display: 'flex',
      alignItems: 'center',
      width: 'calc(100% - 200px)',
      justifyContent: 'center',
      background: `url(${data?.image[0] || data?.image})`,
      borderRadius: '50px',
      backgroundPosition: 'center center',
      backgroundSize: '100% auto',
      fitObject: 'cover',
      position: 'relative',
      marginBottom: '50px'
    },
    blackBg: {
      position: 'absolute',
      zIndex: 1,
      width: '100%',
      height: '100%',
      borderRadius: '50px',
      background: 'rgba(0,0,0,.3)'
    },
    texts: {
      position: 'absolute',
      zIndex: 2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    title: {
      color: 'white',
      maxWidth: '540px',
      textAlign: 'center',
      fontWeight: 900,
      fontSize: '34px',
      lineHeight: 1.1,
      marginBottom: '30px'
    },
    titleBlack: {
      maxWidth: '540px',
      fontWeight: 900,
      fontSize: '22px',
      lineHeight: 1.0,
      marginBottom: '50px',
      marginTop: '50px'
    },
    desc: {
      marginTop: '20px',
      color: 'white',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: 1.1
    }
  }
  useEffect(() => {
    if (id?.length > 0) {
      getAllDataFromPath('articles').then((res) => {
        if (res) {
          res?.forEach(el => {
            if (el?.id === id) {
              const silos = el?.data?.silos
              const temp = []
              res?.forEach((opt) => {
                if (opt?.data?.silos === silos) {
                  temp.push(opt)
                }
              })
              setDataOptional(temp)
              const dataSeoTemp = {}
              dataSeoTemp['seo: category'] = el?.data['seo: category']
              dataSeoTemp['seo: description'] = el?.data['seo: description']
              dataSeoTemp['seo: title'] = el?.data['seo: title']
              dataSeoTemp['seo: fbPageId'] = el?.data['seo: fbPageId']
              dataSeoTemp['seo: keywords'] = el?.data['seo: keywords']
              dataSeoTemp['seo: ogDescription'] = el?.data['seo: ogDescription']
              dataSeoTemp['seo: ogImage'] = el?.data['seo: ogImage']
              dataSeoTemp['seo: ogTitle'] = el?.data['seo: ogTitle']
              dataSeoTemp['seo: page'] = el?.data['seo: page']
              dataSeoTemp['seo: prase'] = el?.data['seo: prase']
              dataSeoTemp['seo: twitterDescription'] = el?.data['seo: twitterDescription']
              dataSeoTemp['seo: twitterId'] = el?.data['seo: twitterId']
              dataSeoTemp['seo: twitterImage'] = el?.data['seo: twitterImage']
              dataSeoTemp['seo: twitterTitle'] = el?.data['seo: twitterTitle']
              setSeoData(dataSeoTemp)
              setData(el?.data)
            }
          })
        }
      })
    }
  }, [id])
  return (
    <>
      <Seo data={seoData}/>
      <MagicLoader variable={id && data}>
        <SizeWindow justifyContent="flex-start">
          <Box sx={style.root}>
            <Grid container spacing={4}>
              <Grid item md={12} xs={12}>
                <Box sx={style.box}>
                  <Box sx={style.banner}>
                    <Box sx={style.texts}>
                      <Typography variant="h1" sx={style.title}>
                        {data?.title}
                      </Typography>
                    </Box>
                    <Box sx={style.blackBg}/>
                  </Box>
                  <Typography className="editorText" sx={style.desc}
                              dangerouslySetInnerHTML={{__html: data?.description}}/>
                  <Tags data={data}/>
                  <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                    <FacebookShareButton url={window.location.href} quotes={data?.title}>
                      <FacebookIcon />
                    </FacebookShareButton>
                    <Typography sx={{ marginLeft: '20px' }}>Udostępnij artykuł na Facebooku</Typography>
                  </Box>
                  <Divider sx={{marginBottom: '50px', marginTop: '50px'}}/>
                  <Box sx={{display: 'flex'}}>
                    <Avatar sx={{width: '100px', height: '100px'}} src={logo} alt="Profilowe Complex Finance"/>
                    <Box sx={{display: 'flex', flexDirection: 'column', marginLeft: '20px'}}>
                      <Typography variant="span" sx={{fontSize: '16px'}}>Napisane przez</Typography>
                      <Typography variant="span"
                                  sx={{fontSize: '18px', fontWeight: '600', marginBottom: '8px', marginTop: '3px'}}>
                        Zespół Complex Finance - <a href="https://complexfinance.pl" target="_blank"
                                                    rel="noreferrer">complexfinance.pl</a>
                      </Typography>
                      <Typography variant="span" sx={{fontSize: '16px'}}>
                        Nasza firma księgowa oferuje kompleksową obsługę finansową, gwarantując rzetelne prowadzenie
                        księgowości, przygotowywanie deklaracji podatkowych i śledzenie zmian prawnych. Działamy zgodnie
                        z najwyższymi standardami, umożliwiając przedsiębiorcom skupienie się na rozwoju biznesu, mając
                        pewność, że aspekty finansowe są w profesjonalnych rękach.
                      </Typography>
                    </Box>
                  </Box>
                  {
                    dataOptional?.length > 1 && (
                      <Box>
                        <Typography sx={style.titleBlack}>Posty powiązane</Typography>
                        <Grid container spacing={4}>
                          {
                            dataOptional?.map((el, index) => {
                              const data = el?.data
                              const id = el?.id
                              if (el?.data?.silos === data?.silos) {
                                return (
                                  <Grid item md={4} xs={12} key={index}>
                                    <ArticlePreview
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        cursor: 'pointer',
                                        marginBottom: '20px'
                                      }}
                                      sizeImageHeight={'250px'}
                                      noAuthor
                                      limit={300}
                                      title={data?.title}
                                      descriptionSmall={data?.description}
                                      image={data?.image[0]}
                                      id={id}
                                    />
                                  </Grid>
                                )
                              }
                              return ''
                            })
                          }
                        </Grid>
                      </Box>
                    )
                  }
                </Box>
              </Grid>
            </Grid>
          </Box>
        </SizeWindow>
        <Footer/>
      </MagicLoader>
    </>
  )
}

export default PreviewArticlePage
