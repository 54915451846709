import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import {Avatar, Grid} from '@mui/material'
import profile from '../../../assets/images/profile.jpg'
import StringHelper from '../../../helpers/StringHelper/StringHelper'
import {useHistory} from 'react-router'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'

const ArticlePreview = ({title, descriptionSmall, image, date, viewLine = false, sx = null, noAuthor = false, id, sizeImageHeight = null, limit = 200}) => {
  const history = useHistory()
  const style = {
    root: {
      display: 'flex',
      flexDirection: BreakpointsHelper.isMobile() ? 'column' : 'row-reverse',
      cursor: 'pointer',
      justifyContent: viewLine ? 'space-between' : 'flex-start',
      alignItems: 'flex-start',
    },
    h2: {
      fontSize: '22px',
      lineHeight: 1.0,
      marginRight: viewLine ? '0' : '40px',
      fontWeight: '900',
      textAlign: 'left',
      marginTop: '20px',
      width: viewLine ? '100%' : 'calc(100% - 40px)'
    },
    h3: {
      paddingRight: '40px',
      lineHeight: 1.0,
      '> *': {
        fontSize: '22px !important',
      },
      marginRight: viewLine ? '0' : '40px',
      fontWeight: '400',
      marginTop: '10px',
      textAlign: 'left',
      width: viewLine ? '100%' : 'calc(100% - 40px)',
      marginBottom: '10px',
      textOverflow: 'hidden',
    },
    image: {
      width: viewLine ? BreakpointsHelper.isMobile() ? '100%' : '200px' : BreakpointsHelper.isMobile() ? '100%' : 'calc(100% - 40px)',
      height: sizeImageHeight || (viewLine ? '200px' : noAuthor ? '200px' : 'auto'),
      objectFit: 'cover',
      marginLeft: viewLine ? BreakpointsHelper.isMobile() ? '0' : '20px' : '0',
      marginTop: '20px',
      borderRadius: '20px',
      marginRight: viewLine ? '0' : '40px'
    }
  }
  const handleOpenArticle = () => {
    history.push(`/showArticle/${id}`)
  }
  return (
    <Box sx={sx || (viewLine ? style.root : {cursor: 'pointer'})} onClick={() => handleOpenArticle()}>
      {
        image ? (
          <img
            src={image}
            alt="active image"
            style={style.image}
          />
        ) : (
          <Box sx={style.image}>
            <Box sx={{ background: 'grey', borderRadius: '20px', width: '100%', height: '100%' }}/>
          </Box>
        )
      }
      <Box>
        <Typography variant="h3" sx={style.h2}>
          {title}
        </Typography>
        <Box variant="p" className={'textsArticleSmall'} sx={style.h3} dangerouslySetInnerHTML={{ __html: StringHelper.add3Dots(descriptionSmall, limit) }}/>
        <Typography sx={{ color: '#2cbc63', fontWeight: '600' }}>Czytaj dalej</Typography>
      </Box>
    </Box>
  )
}

export default ArticlePreview
