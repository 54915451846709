import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {Grid, Link} from '@mui/material'
import Slider from '../../components/atoms/Slider/Slider'
import Category from '../../components/atoms/Category/Category'
import Button from '@mui/material/Button'
import SizeWindow from '../../components/atoms/SizeWindow/SizeWindow'
import NewsPosts from '../../components/molecules/NewsPosts/NewsPosts'
import Footer from '../../components/molecules/Footer/Footer'
import {useEffect, useState} from 'react'
import {getAllDataFromPath} from '../../firebase'
import MagicLoader from '../../components/atoms/MagicLoader/MagicLoader'
import Seo from '../../components/molecules/Seo/Seo'
import ContactForm from '../../components/molecules/ContactForm/ContactForm'
import {ReactComponent as Cat1} from '../../assets/images/icons/001-assign.svg'
import {ReactComponent as Cat2} from '../../assets/images/icons/003-assign.svg'
import {ReactComponent as Cat3} from '../../assets/images/icons/008-checklist.svg'
import {ReactComponent as Cat4} from '../../assets/images/icons/004-assign.svg'
import {ReactComponent as Cat5} from '../../assets/images/icons/005-multitasking.svg'
import {ReactComponent as Cat6} from '../../assets/images/icons/009-teamwork.svg'
import AboutImage from '../../assets/images/Zdjęcia (3).png'
import Page from '../../components/molecules/Page/Page'
import Dot from '../../components/atoms/Dot/Dot'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'

const HomePage = (props) => {
    const [categories, setCategories] = useState(null)
    const [articles, setArticles] = useState(null)
    const [seoData, setSeoData] = useState(null)
    const style = {
        h2: {
            fontSize: '42px',
            fontWeight: '600',
            textAlign: 'left',
            marginTop: '10px',
            width: '100%'
        },
        cat: {
            width: '60px',
            fill: '#2cbc63',
            marginBottom: '20px',
        },
        title: {
            fontSize: BreakpointsHelper.isMobile() ? '30px' : '52px',
            fontWeight: '600',
            marginBottom: '30px',
            textAlign: 'center',
        },
        h2Center: {
            fontSize: '38px',
            fontWeight: '600',
            textAlign: 'center',
            marginTop: '10px',
            width: '100%',
            marginBottom: '30px',
        },
        h3: {
            fontSize: '22px',
            fontWeight: '400',
            marginTop: '10px',
            textAlign: 'center',
            width: '100%'
        },
        desc: {
            fontSize: '22px'
        },
        h3Topic: {
            fontSize: '22px',
            fontWeight: '400',
            marginTop: '10px',
            textAlign: BreakpointsHelper.isMobile() ? 'left' : 'left',
            width: '100%'
        },
        h3Center: {
            fontSize: '22px',
            fontWeight: '400',
            marginTop: '10px',
            textAlign: 'center',
            width: '100%'
        },
        h4: {
            fontSize: '28px',
            fontWeight: '600',
            textAlign: 'center',
            display: 'flex',
            margin: '0 auto',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '10px',
            width: '100%',
            marginBottom: '20px',
            minHeight: '80px'
        },
        image: {
            width: '100%',
            display: 'flex',
            maxWidth: '320px',
            marginBottom: '30px'
        },
        box: {
            display: 'flex',
            flexDirection: 'column',
            background: 'white',
            padding: '40px',
            borderRadius: '30px',
            minHeight: '350px',
            transition: '0.3s all',
            justifyContent: 'flex-start',
            cursor: 'pointer',
            '&:hover': {
                transform: 'scale(1.2)',
                transition: '0.3s all',
            }
        },
    }
    const getSeo = () => {
        getAllDataFromPath('seo').then((res) => {
            if (res) {
                setSeoData(res)
            }
        })
    }
    useEffect(() => {
        if (!articles) {
            getAllDataFromPath('articles').then((res) => {
                if (res) {
                    setArticles(res)
                }
            })
        } else {
            getAllDataFromPath('categories').then((res) => {
                if (res) {
                    const temp = []
                    res.forEach(el => {
                        const data = el?.data
                        const id = el?.id
                        let count = 0
                        articles?.forEach(art => {
                            if (art?.data?.category === id) {
                                count++
                            }
                        })
                        temp.push({
                            name: data?.name,
                            image: data?.image?.length > 0 ? data?.image[0] : '',
                            link: `/showCategories/${id}`,
                            articles: count
                        })
                    })
                    temp?.sort((el, next) => next?.articles - el?.articles)
                    setCategories(temp)
                }
            })
        }
    }, [articles])
    useEffect(() => {
        getSeo()
    }, [])
    return (
        <MagicLoader variable={categories}>
            <Seo data={seoData?.length > 0 ? seoData.filter(el => el.id === 'HomePage')[0]?.data : {}}/>
            <SizeWindow>
                <Slider/>
            </SizeWindow>
            <Page
                revert
                image={AboutImage}
                small={'Nowoczesna księgowość na miarę Twoich oczekiwań'}
                description={(
                    <>
                        <Typography sx={style.desc}>
                            W naszym biurze rachunkowym przekraczamy tradycyjne ramy księgowości, oferując usługi
                            dostosowane
                            do dynamicznie zmieniającego się świata biznesu. Specjalizujemy się w <b>kompleksowej
                            obsłudze</b> CEIDG, spółek i
                            firm, dostarczając rozwiązania, które ułatwiają zarządzanie finansami i
                            przyczyniają się do wzrostu Twojej działalności. Nasza oferta obejmuje
                            nie tylko <b>pełną księgowość</b> i obsługę kadrowo-płacową, ale także
                            rozwijanie zaawansowanych modeli biznesowych i finansowych, jak również
                            precyzyjne analizy i raporty kontrolingowe. Dzięki indywidualnemu
                            podejściu do każdego klienta, jesteśmy w stanie dostosować nasze usługi
                            do unikalnych potrzeb Twojej firmy, zapewniając wsparcie na każdym
                            etapie jej rozwoju. Nasza wiedza i doświadczenie pozwalają nam na
                            efektywną optymalizację procesów finansowych, co przekłada się na realne
                            korzyści dla Twojej działalności.
                        </Typography>
                    </>
                )}
            />
            <SizeWindow>
                <Box sx={{marginTop: '0', marginBottom: '50px'}}>
                    <Grid container spacing={BreakpointsHelper.isMobile() ? 2 : 4}>
                        <Grid item md={12} xs={12}>
                            <Box sx={{
                                display: 'flex',
                                width: '100%',
                                alignItems: 'center',
                                justifyContent: 'center',
                                maxWidth: '1024px',
                                margin: '0 auto'
                            }}>
                                <Typography variant="h1" sx={style.title}>Czym zajmuje się nasze biuro rachunkowe <Dot
                                    char={'?'}/></Typography>
                            </Box>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Box sx={style.box}>
                                <Link href={'/bookkeeping'} sx={{ textDecoration: 'none' }}>
                                    <Typography variant="h2" sx={style.h4}>
                                        <Cat1 style={style.cat}/>
                                        Księgowość
                                    </Typography>
                                    <Typography variant="h3" sx={style.h3}>
                                        Nasze biuro rachunkowe to miejsce, gdzie CEIDG, spółki i firmy
                                        znajdą wsparcie na miarę swoich potrzeb. Zapewniamy kompleksową obsługę
                                        księgową, dostosowaną do różnorodnych form prowadzonej działalności. Z
                                        nami zarządzanie finansami staje się proste i efektywne.
                                    </Typography>
                                </Link>
                            </Box>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Box sx={style.box}>
                                <Link href={'/hr'} sx={{ textDecoration: 'none' }}>
                                    <Typography variant="h2" sx={style.h4}>
                                        <Cat2 style={style.cat}/>
                                        Kadry i płace
                                    </Typography>
                                    <Typography variant="h3" sx={style.h3}>
                                        Potrzebujesz precyzyjnej obsługi kadrowo-płacowej? Dzięki naszemu
                                        wsparciu, wszystkie procesy związane z listami płac, rachunkami,
                                        delegacjami, umowami i ZUS-em przebiegają gładko i bez zakłóceń. Nasi
                                        eksperci odciążą Cię od administracyjnych obowiązków.
                                    </Typography>
                                </Link>
                            </Box>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Box sx={style.box}>
                                <Link href={'/controlling'} sx={{ textDecoration: 'none' }}>
                                    <Typography variant="h2" sx={style.h4}>
                                        <Cat3 style={style.cat}/>
                                        Kontroling
                                    </Typography>
                                    <Typography variant="h3" sx={style.h3}>
                                        W Complex Finance oferujemy analizy finansowe i strategiczne planowanie,
                                        aby wspierać stabilność i wzrost Twojej firmy. Nasze indywidualnie
                                        dopasowane rozwiązania zapewniają przejrzystość finansów i są
                                        ukierunkowane na to, byś w przyszłości podejmował świadome decyzje
                                        biznesowe.
                                    </Typography>
                                </Link>
                            </Box>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Box sx={style.box}>
                                <Link href={'/managementServices'} sx={{ textDecoration: 'none' }}>
                                    <Typography variant="h2" sx={style.h4}>
                                        <Cat4 style={style.cat}/>
                                        Usługi zarządzania
                                    </Typography>
                                    <Typography variant="h3" sx={style.h3}>
                                        Wszelkie usługi zarządzania i nadzoru finansowo-księgowego, w tym
                                        obsługa SSC, dostosowujemy do specyficznych wymagań Twojej organizacji.
                                        Z nami zyskasz nie tylko kontrolę nad finansami, ale też pewność, że są
                                        one zarządzane w sposób przemyślany i z najwyższą starannością.
                                    </Typography>
                                </Link>
                            </Box>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Box sx={style.box}>
                                <Link href={'/dueDiligence'} sx={{ textDecoration: 'none' }}>
                                    <Typography variant="h2" sx={style.h4}>
                                        <Cat5 style={style.cat}/>
                                        Due diligence
                                    </Typography>
                                    <Typography variant="h3" sx={style.h3}>
                                        Pomagamy w głębokiej analizie i ocenie sytuacji finansowej i podatkowej
                                        przedsiębiorstw. Nasze usługi due diligence są kluczem do zrozumienia
                                        finansów Twojej firmy i jej optymalizacji. Skorzystaj z wieloletniego
                                        doświadczenia, aby skutecznie planować przyszłość.
                                    </Typography>
                                </Link>
                            </Box>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Box sx={style.box}>
                                <Link href={'/business'} sx={{ textDecoration: 'none' }}>
                                    <Typography variant="h2" sx={style.h4}>
                                        <Cat6 style={style.cat}/>
                                        Doradztwo biznesowo – ekonomiczne
                                    </Typography>
                                    <Typography variant="h3" sx={style.h3}>
                                        Specjalizujemy się w szerokim spektrum produktów finansowych, takich jak
                                        kredyty, leasing, dotacje czy faktoring, zapewniając kompleksowe
                                        wsparcie w wyborze najlepszych rozwiązań dla Twojego biznesu. Dzięki
                                        naszej ekspertyzie, Twoja firma będzie rosła na solidnych fundamentach.
                                    </Typography>
                                </Link>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{margin: '100px auto', display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <Typography variant="h2" sx={style.h2}>
                                Najczęściej wybierane tematy
                            </Typography>
                            <Typography variant="h3" sx={style.h3Topic}>
                                Przegląd kluczowych zagadnień księgowych
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end'
                                }}
                            >
                                <Button
                                    fullWidth={BreakpointsHelper.isMobile()}
                                    sx={{
                                        marginRight: '20px',
                                        color: 'white',
                                        borderRadius: '20px !important',
                                        height: '46px'
                                    }}
                                    onClick={() => window.open('/showCategories', '_self')}
                                    variant="contained"
                                >
                                    Wszystkie dostępne kategorie
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        padding: '50px 0',
                        flexWrap: 'wrap'
                    }}
                >
                    {
                        categories?.map((el, index) => {
                            if (index < 5) {
                                return (
                                    <Category index={index} el={el} key={index}/>
                                )
                            }
                            return ''
                        })
                    }
                </Box>
                <NewsPosts {...props} />
                <ContactForm/>
            </SizeWindow>
            <Footer/>
        </MagicLoader>
    )
}

export default HomePage
