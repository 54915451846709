import Page from '../../components/molecules/Page/Page'
import Footer from '../../components/molecules/Footer/Footer'
import Typography from '@mui/material/Typography'
import Seo from '../../components/molecules/Seo/Seo'
import AboutImage from '../../assets/images/old/18.png'
import AboutImage2 from '../../assets/images/old/38.png'
import {Button} from '@mui/material'
import ContactBanner from '../../components/molecules/ContactBanner/ContactBanner'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'

const AboutPage = () => {
  const style = {
    title: {
      fontSize: '26px',
      fontWeight: '600',
    },
    desc: {
      fontSize: '22px'
    }
  }
  return (
    <>
      <Seo data={{'seo: title': 'Complex Finance - O nas'}}/>
      <Page
        image={AboutImage}
        image2={AboutImage2}
        title={'O nas'}
        small={'Dostarczamy wiedzę, której możesz zaufać'}
        description={(
          <>
            <Typography sx={style.desc}>
              Za każdym wielkim przedsięwzięciem stoi wizja. <b>Complex Finance</b> powstało
              z pragnienia dostarczania usług księgowych na nieosiągalnym wcześniej
              poziomie. Była to odpowiedź na rosnące potrzeby rynku, który domagał się
              profesjonalizmu i innowacji.
            </Typography>
            <Typography sx={style.desc}>
              W erze cyfryzacji zrozumieliśmy, że <b>nowoczesne rozwiązania informatyczne </b>
              to klucz do przewagi konkurencyjnej. Dlatego zainwestowaliśmy w
              technologię, która umożliwia naszym klientom dostęp do narzędzi, jakie
              wcześniej były zarezerwowane dla korporacyjnych gigantów.
            </Typography>
            <Typography sx={style.desc}>
              To, co nas wyróżnia, to ludzie. Nasi specjaliści to <b>weterani branży</b>,
              którzy zdobywali doświadczenie w największych firmach w kraju i za
              granicą. Ich wiedza i umiejętności to nasz najcenniejszy kapitał.
            </Typography>
          </>
        )}
        description2={(
          <>
            <Typography sx={style.desc}>
              Rozpoczęliśmy od <b>księgowości</b>, ale nasza wizja była szersza. Współczesny
              biznes potrzebuje nie tylko dokładnych bilansów, ale także analiz,
              strategii i kierunków rozwoju. Dlatego poszerzyliśmy naszą ofertę, by
              stać się wszechstronnym partnerem dla firm w każdej fazie ich rozwoju.
            </Typography>
            <Typography sx={style.desc}>
              Współpraca z ekspertami z różnych dziedzin była dla nas oczywista.
              Dzięki temu naszym klientom oferujemy nie tylko <b>doskonałą księgowość</b>,
              ale także wsparcie prawne, podatkowe i audytorskie. Nasza siła tkwi w
              zrozumieniu, że prawdziwy sukces to efekt pracy zespołowej.
            </Typography>
            <Typography sx={style.desc}>
              Nasz cel jest prosty: chcemy, abyś mógł skupić się na tym, co robisz
              najlepiej, wiedząc, że Twoje finanse są w najlepszych rękach. Complex
              Finance to nie tylko usługi, to <b>partnerstwo</b>. To obietnica stałego
              wsparcia, indywidualnego podejścia i nieustannej dbałości o Twoje
              interesy. Bo kiedy Ty się rozwijasz i osiągasz sukces, my również
              czujemy się spełnieni.
            </Typography>
              <Button
                  onClick={() => window.open('/contact', '_self')}
                  variant="contained"
                  sx={{ marginRight: '20px', color: 'white', borderRadius: '20px !important', height: '46px', marginTop: '30px', width: BreakpointsHelper.isMobile() ? '100%' : 'auto' }}
              >
                  Skontaktuj się z nami
              </Button>
          </>
        )}
      />
      <ContactBanner />
      <Footer/>
    </>
  )
}

export default AboutPage
