export default {
  getUrlFromPathname: (url) => {
    return `${process.env.REACT_APP_FIREBASE_STORAGE_LINK}${process.env.REACT_APP_STORAGE_BUCKET}/o/${url.replace('/', '%2F')}`
  },
  getPathNameFromUrl: (url, storage = 'gallery') => {
    if (url?.length > 0 && storage === 'gallery') {
      return url.replace(`${process.env.REACT_APP_FIREBASE_STORAGE_LINK}${process.env.REACT_APP_STORAGE_BUCKET_IMPORT}/o/`, '').split('?')[0].replace('%2F', '/')
    }
    return ''
  },
  decodeFile: (file) => {
    return decodeURIComponent(file.replace(`${process.env.REACT_APP_FIREBASE_STORAGE_LINK}${process.env.REACT_APP_STORAGE_BUCKET_IMPORT}/o/`, '').split('?')[0])
  }
}
