import Page from '../../components/molecules/Page/Page'
import Footer from '../../components/molecules/Footer/Footer'
import Typography from '@mui/material/Typography'
import {Grid} from '@mui/material'
import Seo from '../../components/molecules/Seo/Seo'
import ControllingImage from '../../assets/images/old/39.png'
import ContactBanner from '../../components/molecules/ContactBanner/ContactBanner'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import {ReactComponent as Box1} from '../../assets/images/icons/015-team management.svg'
import {ReactComponent as Box2} from '../../assets/images/sectionIcons/Przeprowadzamy.svg'
import {ReactComponent as Box3} from '../../assets/images/sectionIcons/Rozumiemy.svg'
import {ReactComponent as Box4} from '../../assets/images/sectionIcons/Budujemy.svg'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'

const ControllingPage = () => {
  const style = {
    desc: {
      '> *': {
        fontSize: '22px',
        fontWeight: '600',
      },
      fontSize: '22px',
    },
    titleBox: {
      fontSize: '22px',
      textAlign: 'left',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginBottom: '10px',
    },
    img: {
      fill: '#2cbc63',
      width: '40px',
      marginRight: '15px',
    },
    image: {
      width: '100%',
      display: 'flex',
      maxWidth: '320px',
      marginBottom: '30px'
    },
    box: {
      display: 'flex',
      flexDirection: 'column',
      background: 'white',
      padding: '40px',
      borderRadius: '30px',
      minHeight: '350px',
      transition: '0.3s all',
      justifyContent: 'flex-start',
      cursor: 'pointer',
      '&:hover': {
        transform: 'scale(1.2)',
        transition: '0.3s all',
      }
    },
    h3: {
      fontSize: '22px',
      fontWeight: '400',
      marginTop: '10px',
      textAlign: 'center',
      width: '100%'
    },
    h3Topic: {
      fontSize: '22px',
      fontWeight: '400',
      marginTop: '10px',
      textAlign: BreakpointsHelper.isMobile() ? 'left' : 'left',
      width: '100%'
    },
    h3Center: {
      fontSize: '22px',
      fontWeight: '400',
      marginTop: '10px',
      textAlign: 'center',
      width: '100%'
    },
    h4: {
      fontSize: '28px',
      fontWeight: '600',
      textAlign: 'center',
      display: 'flex',
      margin: '0 auto',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '10px',
      width: '100%',
      marginBottom: '20px',
      minHeight: '80px'
    },
    cat: {
      width: '60px',
      fill: '#2cbc63',
      marginBottom: '20px',
    },
  }
  return (
    <>
      <Seo data={{ 'seo: title': 'Complex Finance - Kontroling' }}/>
      <Page
        image={ControllingImage}
        title={'Kontroling'}
        small={'Inteligentny Kontroling z Complex Finance'}
        description={(
          <>
            <Typography sx={{ marginBottom: '50px !important', fontSize: '22px' }}>
              Nasze biuro wyróżnia się na tle tradycyjnej księgowości, oferując
              zaawansowane usługi kontrolingu, które skupiają się na generowaniu
              wartości dodanej dla Twojej firmy. Zwracamy uwagę na szerszą
              perspektywę, dlatego na podstawie danych finansowych wychwycimy
              wszystkie ewentualne luki i problemy. Uważnie sprawdzimy, na czym
              zarabiasz, a co przynosi Ci straty. Nie ograniczamy się jedynie do liczb
              - naszym celem jest dostarczanie rozwiązań, które umożliwiają
              przemyślane decyzje strategiczne.
            </Typography>
            <Button
                onClick={() => window.open('/contact', '_self')}
                variant="contained"
                sx={{ marginRight: '20px', color: 'white', borderRadius: '20px !important', height: '46px', marginTop: '30px', width: BreakpointsHelper.isMobile() ? '100%' : 'auto' }}
            >
              Napisz do nas
            </Button>
          </>
        )}
      />
      <Page
        centerHeading
        section2Center
        title={'Kontroling'}
        small={'Jakie działania podejmujemy'}
        description={(
          <>
            <Box sx={{ maxWidth: '1024px', margin: '50px auto 0 auto' }}>
              <Grid container spacing={8}>
                <Grid item md={6} xs={12}>
                  <Box sx={style.box}>
                    <Typography variant="h2" sx={style.h4}>
                      <Box1 style={style.cat}/>
                      Wykorzystujemy
                    </Typography>
                    <Typography variant="h3" sx={style.h3}>
                      Centra kosztów i inne zaawansowane metody kontrolingowe - jako
                      fundamenty skutecznego zarządzania przedsiębiorstwem. Centra kosztów,
                      definiowane jako specyficzne struktury organizacyjne lub obszary
                      funkcjonalne, odgrywają kluczową rolę w monitorowaniu i kontroli
                      wydatków. Dzięki nim, możesz precyzyjnie śledzić koszty związane z
                      różnymi segmentami działalności – od działów po projekty i produkty.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box sx={style.box}>
                    <Typography variant="h2" sx={style.h4}>
                      <Box2 style={style.cat}/>
                      Przeprowadzamy
                    </Typography>
                    <Typography variant="h3" sx={style.h3}>
                      Analizy odchyleń, które są sercem całego procesu. Skupiamy się na
                      identyfikacji różnic między rzeczywistymi wynikami finansowymi a tymi
                      planowanymi lub osiąganymi w przeszłości. To podejście umożliwia
                      głębokie zrozumienie dynamiki finansowej Twojego biznesu, wskazując na
                      potencjalne obszary do optymalizacji i rozwijania.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box sx={style.box}>
                    <Typography variant="h2" sx={style.h4}>
                      <Box3 style={style.cat}/>
                      Rozumiemy
                    </Typography>
                    <Typography variant="h3" sx={style.h3}>
                      Znaczenie danych operacyjnych w procesie doskonalenia raportowania
                      wewnętrznego. Na początku naszej współpracy analizujemy strukturę i
                      charakter danych generowanych przez Twój system operacyjny. Pozwoli to
                      na stworzenie spersonalizowanych, precyzyjnych raportów, które są
                      niezbędne dla efektywnego zarządzania firmą.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box sx={style.box}>
                    <Typography variant="h2" sx={style.h4}>
                      <Box4 style={style.cat}/>
                      Budujemy
                    </Typography>
                    <Typography variant="h3" sx={style.h3}>
                      Budżety, biznes plany i forecasty, które pomagają przedsiębiorstwom
                      planować, monitorować i oceniać swoje działania oraz osiągnięcia. Każde
                      z tych narzędzi ma swoje unikalne cechy i cele, ale wspólnie stanowią
                      kompleksowy framework wspierający procesy decyzyjne.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      />
      <ContactBanner />
      <Footer/>
    </>
  )
}

export default ControllingPage
