import PropTypes from 'prop-types'
import NavigationPreview from '../../components/molecules/NavigationPreview/NavigationPreview'
import MagicLoader from '../../components/atoms/MagicLoader/MagicLoader'

const PreviewLayout = (props) => {
  const Component = props.component
  const {location} = props
  const style = {
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'start',
      flexDirection: 'column',
      padding: '90px 0 0 0',
      width: '100%'
    }
  }
  return (
    <div style={style.root}>
      <NavigationPreview location={location}/>
      <MagicLoader>
        <Component location={location}/>
      </MagicLoader>
    </div>
  )
}

PreviewLayout.defaultProps = {
  location: false
}

PreviewLayout.propsType = {
  location: PropTypes.object
}

export default PreviewLayout
